import { z } from "zod";

const shipmentInfoSchema = z.object({
  shipmentId: z.number().nullable(),
  stopSeq: z.number().nullable(),
  eta: z.string().nullable(),
});

const shipmentGeoCodeSchema = z.object({
  shipmentId: z.number().nullable(),
  lat: z.number().nullable(),
  lng: z.number().nullable(),
});

export const routeAssignmentResponseSchema = z.object({
  id: z.number(),
  date: z.number(),
  sicId: z.number(),
  location: z.string().nullable(),
  serviceCenterCode: z.string().nullable(),
  name: z.string(),
  startTime: z.date().nullable(),
  endTime: z.date().nullable(),
  presetRouteId: z.number().nullable(),
  driverId: z.number().nullable(),
  trailerEquipmentId: z.number().nullable(),
  doorId: z.number().nullable(),
  isCommitted: z.boolean(),
  endPoint: z.string().nullable(),
  shipmentIds: z.number().array(),
  status: z.string().nullable(),
  shipments: z.array(shipmentInfoSchema),
  shipmentsGeocode: z.array(shipmentGeoCodeSchema),
});

export const trapAssignmentResponseSchema = z.object({
  id: z.number(),
  date: z.number(),
  sicId: z.number(),
  name: z.string(),
  presetRouteId: z.number().nullable(),
  equipmentId: z.number().nullable(),
  doorId: z.number().nullable(),
  isCommitted: z.boolean(),
  shipmentIds: z.number().array().nullable(),
  status: z.string().nullable(),
  shipments: z.array(shipmentInfoSchema).nullable(),
  shipmentsGeocode: z.array(shipmentGeoCodeSchema).nullable(),
});

export type RouteAssignmentResponse = z.infer<
  typeof routeAssignmentResponseSchema
>;

export type TrapAssignmentResponse = z.infer<
  typeof trapAssignmentResponseSchema
>;
