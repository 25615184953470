import { Chip, colors, useTheme } from "@mui/material";

interface StatusTagChipProps {
  /** Status displayed in the tag. */
  status: string;
  /** Callback for onClick */
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /** Icon shown at the right */
  chipIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  /** Planned will have green color */
  isPlanned?: boolean;
}

export const StatusTagChip = ({
  status,
  onClick,
  chipIcon,
  isPlanned = false,
}: StatusTagChipProps) => {
  const theme = useTheme();

  return (
    <Chip
      label={status}
      size="small"
      variant="outlined"
      sx={{
        textTransform: "uppercase",
        color: isPlanned
          ? theme.palette.primary.contrastText
          : colors.common.black,
        backgroundColor: isPlanned
          ? theme.palette.success.main
          : theme.palette.grey[300],
        "&:hover": {
          backgroundColor: isPlanned
            ? `${theme.palette.success.light} !important`
            : `${theme.palette.grey[400]} !important`,
        },
      }}
      onClick={onClick}
      // Hack. onDelete must be set to show chipIcon. See https://mui.com/material-ui/api/chip/#chip-prop-deleteIcon
      // It should also call onClick to make the chipIcon clickable
      onDelete={chipIcon ? onClick : undefined}
      deleteIcon={chipIcon}
    />
  );
};
