import { Table } from "@tanstack/react-table";
import { TablePagination as MuiTablePagination } from "@mui/material";
import { ChangeEvent, memo, useEffect } from "react";
import { pageSizes } from "../../../constants/table-page-size";

type TablePaginationProps<TData> = {
  table: Table<TData>;
  data?: TData[];
};

const TablePagination = <TData,>({
  data = [],
  table,
}: TablePaginationProps<TData>) => {
  useEffect(
    function defaultPaginationToAllRows() {
      if (data.length > 0) table.setPageSize(data.length);
    },
    [data, table]
  );

  const handleChangePage = (_: unknown, newPage: number) =>
    newPage > table.getState().pagination.pageIndex
      ? table.nextPage()
      : table.previousPage();

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    table.setPageSize(Number(event.target.value));
    table.setPageIndex(0);
  };
  return (
    <MuiTablePagination
      sx={{ width: "100%", overflow: "visible" }}
      rowsPerPageOptions={[...pageSizes, { value: data.length, label: "All" }]}
      component="div"
      count={data.length}
      rowsPerPage={table.getState().pagination.pageSize}
      page={table.getState().pagination.pageIndex}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default memo(TablePagination) as <TData>(
  props: TablePaginationProps<TData>
) => React.JSX.Element;
