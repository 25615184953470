import { Box, Chip } from "@mui/material";

type DropdownStatusItemProps = {
  label: string;
  status: string;
  bgColor?: string;
  color?: string;
};

const DropdownStatusItem = ({
  label,
  bgColor: backgroundColor,
  color,
  status,
}: DropdownStatusItemProps) => (
  <Box width="100%" display="flex" justifyContent="space-between">
    {label}
    <Chip
      sx={{
        width: "min-content",
        height: "24px",
        backgroundColor,
        color,
      }}
      label={status}
    />
  </Box>
);

export default DropdownStatusItem;
