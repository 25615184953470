import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IconButton, Stack, useTheme } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Filter } from "../../../../types/filter";
import { SearchBox } from "../../../../components/shared/SearchBox";
import { getActiveAndInactiveFilters } from "../../../../utils/filters";
import { FlexRow } from "../../../../components/shared/layout/Flex";
import FilterMultiselect from "../../../../components/shared/FilterMultiselect";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { AddFilterMenu } from "./AddFilterMenu";

import QuickFilter from "./QuickFilter";
import { ETAFilter } from "./ETAFilter";

type FiltersProps = {
  filters: Filter[];
  setSearchTerm: (searchTerm: string) => void;
  placeholder?: string;
  showEta?: boolean;
  isQuickFilter?: boolean;
  filterSectionId?: string;
};

const getTypeFilter = (filters: Filter[]): Filter => {
  const typeFilter = filters.find((filter) => filter.label === "Type");
  if (!typeFilter) {
    throw new Error(
      "Type filter not found. Please ensure the filter is provided."
    );
  }
  return typeFilter;
};

const getETAFilter = (filters: Filter[]): Filter => {
  const etaFilter = filters.find((filter) => filter.label === "ETA");
  if (!etaFilter) {
    throw new Error(
      "ETA filter not found. Please ensure the filter is provided."
    );
  }
  return etaFilter;
};

const Filters = ({
  filters,
  setSearchTerm,
  placeholder = "Search",
  showEta = false,
  isQuickFilter = false,
  filterSectionId,
}: FiltersProps) => {
  const filtersWrapper = useRef<HTMLDivElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const isQuickFilterFlagEnabled = useIsFeatureFlagEnabled(
    "inbound-quick-filter-client"
  );

  const { activeFilters, inactiveFilters } = getActiveAndInactiveFilters(
    filters.filter((filter) =>
      isQuickFilter
        ? filter.label !== "Type"
        : showEta
          ? filter.label !== "ETA"
          : true
    ),
    searchParams
  );

  const [lastFilterAdded, setLastFilterAdded] = useState<Filter>();

  const scrollStepInPixels = 10;
  const minFiltersToShowScroll = 4;
  const [showScrollButtons, setShowScrollButtons] = useState({
    left: false,
    right: false,
  });

  useEffect(
    function setScrollButtonVisibilityWhenActiveFiltersChange() {
      if (activeFilters.length >= minFiltersToShowScroll) {
        setShowScrollButtons({ left: true, right: true });
      } else {
        setShowScrollButtons({ left: false, right: false });
      }
    },
    [activeFilters.length]
  );

  const sideScroll = (step: number) => {
    const element = filtersWrapper.current;
    if (element === null) return;
    const speed = 10;
    const distance = 200;

    const maxPosition = element.scrollWidth - element.clientWidth;
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
        setShowScrollButtons({
          left: element.scrollLeft !== 0,
          right: element.scrollLeft !== maxPosition,
        });
      }
    }, speed);
  };

  const filteredActiveFilters = isQuickFilter
    ? activeFilters.filter((filter) => filter.label !== "Type")
    : activeFilters;

  return (
    <FlexRow
      style={{ gap: "1.5rem", alignItems: "flex-end", padding: "0.5rem 0" }}
    >
      <SearchBox onValueChange={setSearchTerm} placeholder={placeholder} />
      {showEta && <ETAFilter filter={getETAFilter(filters)} />}
      {isQuickFilterFlagEnabled && isQuickFilter && (
        <QuickFilter filter={getTypeFilter(filters)} />
      )}

      <Stack
        direction="row"
        sx={{
          overflow: "hidden",
          gap: "1.5rem",
        }}
        ref={filtersWrapper}
      >
        {showScrollButtons.left && (
          <ScrollButton
            onClick={() => {
              sideScroll(-scrollStepInPixels);
            }}
            direction="left"
          />
        )}
        {filteredActiveFilters.map((filter) => {
          const activeOptions = searchParams.getAll(filter.queryParam);
          const selectedOptions = filter.options.filter((option) =>
            activeOptions.includes(option.value.toString())
          );
          return (
            <FilterMultiselect
              key={filter.label}
              id={filter.label}
              label={filter.label}
              options={filter.options}
              value={selectedOptions}
              initialOpen={lastFilterAdded?.label === filter.label}
              getDisplayValue={(option) => `${option.label || "All"}`}
              getSelectedDisplayValue={(selected) =>
                selected.length === 0
                  ? "All"
                  : selected.length === 1
                    ? selected[0].label.toString()
                    : `${selected[0].label} (${selected.length})`
              }
              onChange={(options) => {
                setSearchParams((old) => {
                  const prev = new URLSearchParams(old);

                  // Convert current options to a Set for easy lookup
                  const newOptionValues = new Set(
                    options.map((o) => o.value.toString())
                  );

                  // Handle case when no options are selected
                  if (options.length === 0) {
                    prev.set(filter.queryParam, "All");
                  } else {
                    // Remove the previous values of filter.queryParam not in the new options
                    const keysToRemove: string[] = [];
                    prev.forEach((value, key) => {
                      if (
                        key === filter.queryParam &&
                        !newOptionValues.has(value)
                      ) {
                        keysToRemove.push(value);
                      }
                    });
                    keysToRemove.forEach((value) =>
                      prev.delete(filter.queryParam, value)
                    );

                    // Append new options that are selected
                    options.forEach((o) => {
                      if (
                        !prev
                          .getAll(filter.queryParam)
                          .includes(o.value.toString())
                      ) {
                        prev.append(filter.queryParam, o.value.toString());
                      }
                    });
                  }

                  return prev;
                });
              }}
              onRemove={() => {
                setSearchParams((searchParams) => {
                  searchParams.delete(filter.queryParam);
                  return searchParams;
                });
              }}
            />
          );
        })}
        <AddFilterMenu
          onAddFilter={(filter: Filter) => {
            setSearchParams((params) => {
              params.set(filter.queryParam, "All");
              return params;
            });
            setLastFilterAdded(filter);
          }}
          filters={inactiveFilters}
          filterSectionId={filterSectionId}
        />
        {showScrollButtons.right && (
          <ScrollButton
            onClick={() => {
              sideScroll(scrollStepInPixels);
            }}
            direction="right"
          />
        )}
      </Stack>
    </FlexRow>
  );
};

type ScrollButtonProps = {
  onClick: () => void;
  direction: "left" | "right";
};

function ScrollButton({ onClick, direction }: ScrollButtonProps) {
  const theme = useTheme();

  return (
    <IconButton
      aria-label={`Scroll filter list ${direction}`}
      sx={{
        height: 34,
        width: 34,
        borderRadius: 16,
        boxShadow: 3,
        position: "sticky",
        marginX: "-17px",
        [direction]: 5,
        top: 5,
        zIndex: 2,
        backgroundColor: theme.palette.grey[200],
        ":hover": {
          backgroundColor: theme.palette.grey[300],
        },
      }}
      onClick={onClick}
    >
      {direction === "left" ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  );
}

export default Filters;
