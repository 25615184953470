import { Box, styled } from "@mui/material";
import { InboundView } from "../../../types/inbound/inboundView.type";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import { Appointment, Route } from "../../../services/prePlanningService.types";
import { Trap } from "../../../types/planning/trap.type";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import AssignActionButton from "../prePlanning/actionbar/AssignActionButton";
import MergeActionButton from "../prePlanning/actionbar/MergeActionButton";
import BulkPrintButton from "../../../components/shared/BulkPrintButton";
import AddToPlanActionButton from "../prePlanning/actionbar/AddToPlanActionButton";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import CreateActionMenuButton from "./CreateActionMenuButton";
import DeleteButton from "./DeleteButton";
import { SummaryContext } from "./ActionsMenuContext";

export interface InboundActionBarProps {
  activeTab: InboundView;
  selectedRowsContext: SummaryContext[];
  contextMenuActions: ContextMenuAction[];
  selectedBuckets?: Appointment[];
  selectedTraps?: Trap[];
  selectedRoutes?: Route[];
  clearSelection?: () => void;
  loadingTrapIds?: number[];
  setLoadingTrapIds?: (ids: number[]) => void;
}

const SummaryVerticalBar = styled(Box)(({ theme }) => ({
  width: "0px",
  border: "1px solid",
  borderColor: theme.palette.divider,
  flex: "none",
  order: 0,
  flexGrow: 0,
}));

const InboundActionBar = ({
  activeTab,
  contextMenuActions,
  selectedRowsContext,
  selectedBuckets = [],
  selectedTraps = [],
  selectedRoutes = [],
  clearSelection = () => ({}),
  loadingTrapIds = [],
  setLoadingTrapIds,
}: InboundActionBarProps) => {
  const isNewTableEnabled = useIsFeatureFlagEnabled(
    "inbound-new-table-design-client"
  );
  const createActions = contextMenuActions.filter((a) =>
    a.label.startsWith("Create")
  );

  const assignActions = contextMenuActions.filter((a) =>
    a.label.includes(activeTab === "routes" ? "Assign To" : "Assign")
  );

  const mergeAction = contextMenuActions.find(
    (a) => a.label.toLowerCase() === `merge ${activeTab}`
  );
  const [serviceCenter] = useSelectedServiceCenter();
  const [selectedDate] = useDateSearchParamOrFallbackToToday();

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-end" gap="12px">
      {((activeTab === "traps" &&
        selectedTraps.filter((trap) => trap.bills > 0).length === 0) ||
        (activeTab === "routes" &&
          selectedRoutes.filter((route) => route.bills > 0).length === 0)) && (
        <DeleteButton
          activeTab={activeTab}
          selectedTraps={selectedTraps}
          selectedRoutes={selectedRoutes}
          clearSelection={clearSelection}
        />
      )}
      {(!isNewTableEnabled ||
        (activeTab !== "routes" && activeTab !== "traps")) && (
        <CreateActionMenuButton createActions={createActions} />
      )}
      <AssignActionButton assignActions={assignActions} />
      {isNewTableEnabled
        ? (activeTab === "routes" || activeTab === "traps") &&
          mergeAction &&
          selectedRowsContext.length > 1 && (
            <MergeActionButton mergeAction={mergeAction} />
          )
        : (activeTab === "routes" || activeTab === "traps") &&
          mergeAction && <MergeActionButton mergeAction={mergeAction} />}
      {activeTab === "traps" && (
        <BulkPrintButton
          selectedTraps={selectedTraps}
          serviceCenterId={serviceCenter.id}
          loadingTrapIds={loadingTrapIds}
          planDate={selectedDate}
          setLoadingTrapIds={setLoadingTrapIds}
        />
      )}
      {(activeTab === "traps" || activeTab === "appointments") && (
        <>
          <SummaryVerticalBar />
          <AddToPlanActionButton
            selectedTrapOrBucket={
              activeTab === "traps" ? selectedTraps : selectedBuckets
            }
            selectedRowsContext={selectedRowsContext}
            clearSelection={clearSelection}
          />
        </>
      )}
    </Box>
  );
};

export default InboundActionBar;
