import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { http } from "../../../../utils/httpCommon";
import { apiUrls } from "../../../../utils/apiUrls";
import { permissionSchema } from "./userPermissions";

const groupKeys = {
  groups: () => ["groups"],
  group: (groupId: number) => ["groups", groupId],
  groupUsers: (groupId: number) => ["groups", "users", groupId],
};

const userSchema = z.object({
  id: z.string().uuid(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email().nullable(),
  isActive: z.boolean(),
  sicId: z.number().nullable(),
  loginId: z.string().nullable(),
});

const groupUserSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string().nullable(),
  updatedBy: z.string(),
  dateAssigned: z
    .date()
    .optional()
    .transform((val) => dayjs(val)),
  loginId: z.string().nullable(),
});

export const groupSchema = z.object({
  id: z.number(),
  name: z.string(),
  isActive: z.boolean(),
  description: z.string().nullish(),
  modifiedBy: z.string().nullish(),
  modifiedOn: z
    .date()
    .optional()
    .transform((val) => dayjs(val)),
});

const groupSchemaWithCounts = groupSchema.extend({
  permissions: z.number(),
  users: z.number(),
});

const groupSchemaWithCollections = groupSchema.extend({
  permissions: permissionSchema.array(),
  users: userSchema.array(),
});

export const createGroupSchema = z.object({
  name: z.string().min(1, "Name is required").max(30, "Name is too long"),
  description: z.string().max(100, "Description is too long").optional(),
});

const createGroupError = z.object({
  errors: z.object({
    data: z.array(z.string()),
  }),
});

export type Group = z.infer<typeof groupSchema>;
export type GroupWithCount = z.infer<typeof groupSchemaWithCounts>;
export type GroupWithCollections = z.infer<typeof groupSchemaWithCollections>;
export type GroupUser = z.infer<typeof groupUserSchema>;

export const useGroups = () =>
  useQuery({
    queryKey: groupKeys.groups(),
    queryFn: async () =>
      groupSchemaWithCounts
        .array()
        .parse((await http.get(apiUrls.getGroups())).data),
  });

export const useGroupUsers = (groupId: number) =>
  useQuery({
    queryKey: groupKeys.groupUsers(groupId),
    queryFn: async () => {
      const users = groupUserSchema
        .array()
        .parse((await http.get(apiUrls.getGroupUsers(groupId))).data);

      return users;
    },
  });

type CreateGroup = z.infer<typeof createGroupSchema>;
type CreateGroupArgs = {
  onSuccess?: (group: CreateGroup) => void;
  onError?: (error: string, group: CreateGroup) => void;
  onSettled?: () => void;
};

export const useCreateGroup = ({
  onSuccess,
  onError,
  onSettled,
}: CreateGroupArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group: CreateGroup) => {
      await http.post(apiUrls.createGroup(), group);
      return group;
    },
    onSuccess: (group) => {
      onSuccess?.(group);
    },
    onError: (_error, group) => {
      const error = _error as AxiosError;
      const errorData = createGroupError.safeParse(error.response?.data);
      if (errorData.success) {
        onError?.(errorData.data.errors.data[0], group);
      } else {
        onError?.("Unknown Error", group);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: groupKeys.groups(),
      });
      onSettled?.();
    },
  });
};

export const useGroup = (groupId: number) =>
  useQuery({
    queryKey: groupKeys.group(groupId),
    queryFn: async () => {
      const { data: response } = await http.get(apiUrls.getGroup(groupId));
      return groupSchemaWithCollections.parse(response);
    },
  });

type DeleteGroupArgs = {
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
  onSettled: () => void;
};

export const useDeleteGroup = ({
  onSuccess,
  onError,
  onSettled,
}: DeleteGroupArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (permissionId: number) => {
      const url = apiUrls.deleteGroup(permissionId);
      const { status } = await http.delete(url);
      return status;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: groupKeys.groups(),
      });
      onSuccess();
    },
    onError: (_error) => {
      onError(_error.message);
    },
    onSettled: () => {
      onSettled();
    },
  });
};

type UpdateGroupArgs = {
  onSuccess?: (group: Group) => void;
  onError?: (group: Group) => void;
  onSettled?: () => void;
};

export const useUpdateGroup = ({
  onSuccess,
  onError,
  onSettled,
}: UpdateGroupArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group: Group) => {
      const { id, ...updatableFields } = group;
      await http.put(apiUrls.updateGroup(id), updatableFields);
      return group;
    },
    onSuccess: (group) => {
      onSuccess?.(group);
    },
    onError: (_error, group) => {
      onError?.(group);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: groupKeys.groups(),
      });
      onSettled?.();
    },
  });
};

type UpdateGroupPermissionArgs = {
  onSuccess?: () => void;
  onError?: (errorMessage: string) => void;
  onSettled?: () => void;
  groupId: number;
};

export const useUpdateGroupPermissions = ({
  onSuccess,
  onError,
  onSettled,
  groupId,
}: UpdateGroupPermissionArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permissionsIds: number[]) =>
      await http.put(apiUrls.updateGroupPermissions(groupId), permissionsIds),
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (_error) => {
      onError?.(_error.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: groupKeys.groups(),
      });
      onSettled?.();
    },
  });
};

export const useUpdateGroupUsers = ({
  onSuccess,
  onError,
  onSettled,
  groupId,
}: UpdateGroupPermissionArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userIds: string[]) =>
      http.post(apiUrls.updateGroupUsers(groupId), userIds),
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (_error) => {
      onError?.(_error.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: groupKeys.groups(),
      });
      onSettled?.();
    },
  });
};
