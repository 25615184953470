import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";

interface ErrorDialogProps {
  open: boolean;
  errorMessage: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setErrorMessage?: string;
}
const ErrorDialog = ({ open, errorMessage, setOpen }: ErrorDialogProps) => (
  <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
    <DialogTitle sx={{ fontWeight: 600 }}>Something Went Wrong</DialogTitle>
    <Box position="absolute" top={0} right={0}>
      <IconButton onClick={() => setOpen(false)}>
        <Close />
      </IconButton>
    </Box>
    <DialogContent>{errorMessage}</DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          setOpen(false);
        }}
        sx={{ backgroundColor: "#B80D0D" }}
        color="error"
        variant="contained"
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

export default ErrorDialog;
