import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { MouseEventHandler, ReactNode } from "react";
import { LoadingButton } from "../../../../components/shared/LoadingButton";

interface StyledMenuButtonProps {
  children: React.ReactNode;
  startIcon: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  showExpandIcon?: boolean;
  isLoading?: boolean;
  loadingLabel?: string;
}

export const buttonStyle = {
  height: 40,
  alignSelf: "flex-end",
};

export const loadingButtonStyle = {
  ...buttonStyle,
  width: 180,
};

export default function StyledMenuButton({
  children,
  startIcon,
  onClick,
  isLoading = false,
  loadingLabel = "",
  showExpandIcon = false,
}: StyledMenuButtonProps) {
  return (
    <LoadingButton
      sx={buttonStyle}
      variant="outlined"
      startIcon={startIcon}
      endIcon={showExpandIcon && <ExpandMoreOutlinedIcon />}
      onClick={onClick}
      isLoading={isLoading}
      loadingLabel={loadingLabel}
    >
      {children}
    </LoadingButton>
  );
}
