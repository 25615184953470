import dayjs, { Dayjs } from "dayjs";
import { z } from "zod";
import { DaylightDateFormat } from "../constants/DaylightDateFormat";

const timeSchema = z.string().regex(/^(?:[01]\d|2[0-3]):[0-5]\d$/);

export const utcTimestampToLocal = (
  utcTimestamp: any,
  format: DaylightDateFormat = DaylightDateFormat.DATE
): string => {
  if (!utcTimestamp) return "";
  if (utcTimestamp instanceof Date) utcTimestamp = utcTimestamp.toJSON();
  const utc = dayjs.utc(utcTimestamp);
  return utc.local().format(format);
};

export const utcTimestampToSpecifiedZone = (
  utcTimestamp: string,
  zone: string,
  format: DaylightDateFormat = DaylightDateFormat.DATE_TIME_12
): string => dayjs.utc(utcTimestamp).tz(zone).format(format);

export const timeToFormat = (
  time: string,
  format: DaylightDateFormat = DaylightDateFormat.TIME_12
): string => {
  const timeArr = time.split(":");
  return dayjs({
    hour: Number.parseInt(timeArr[0]),
    minute: Number.parseInt(timeArr[1]),
  }).format(format);
};

export const formatDate = (
  date?: string,
  format: DaylightDateFormat = DaylightDateFormat.DATE
): string => {
  if (!date) return "";
  return dayjs(date).format(format);
};

export const timeToISO = (time: string): string => {
  const timeArr = time.split(":");
  return dayjs(
    {
      hour: Number.parseInt(timeArr[0]),
      minute: Number.parseInt(timeArr[1]),
    },
    { utc: true }
  ).toISOString();
};

export const isoToTime = (
  iso: string,
  format: DaylightDateFormat = DaylightDateFormat.TIME_24_WITH_SECONDS
): string => dayjs(iso).format(format);

export const dateToFormat = (
  value: Date | null | undefined,
  format: DaylightDateFormat,
  timeZone?: string
) => {
  if (!value || value.getFullYear() === 1) return "";

  let date = dayjs(value).utc();

  if (timeZone) date = date.tz(timeZone);

  return date.format(format);
};

export function dateToInt(deliveryDate: string): number {
  return Number(deliveryDate.replaceAll("-", ""));
}

export function intToDate(date: number): string {
  return dayjs(date.toString(), "YYYYMMDD").format(
    DaylightDateFormat.DATE_WITH_SHORT_DAY_OF_WEEK
  );
}

export function numberToMilitaryTime(value: number) {
  const stringValue = value.toString().padStart(4, "0");
  return dayjs({
    hour: Number(stringValue.slice(0, 2)),
    minute: Number(stringValue.slice(2, 4)),
  });
}

export function dateTimeToMilitaryNumber(date: Dayjs) {
  return Number(date.format(DaylightDateFormat.TIME_12));
}

export function militaryTimeToDateTime(value: string) {
  return dayjs(value, "HHmm");
}

export function timeOnlyStringToMilitaryTime(value: string | null) {
  if (!value) return "";
  return dayjs(value, "HH:mm:ss").format(DaylightDateFormat.MILITARY_TIME);
}

/**
 * Gets the first day of the week (Sunday) in UTC
 */
export function getStartOfWeekUtc(date?: Dayjs) {
  return date?.startOf("week") ?? dayjs().startOf("week");
}

/**
 * Gets the end of the week (Saturday) in UTC
 */
export function getEndOfWeekUtc(date?: Dayjs) {
  return date?.endOf("week") ?? dayjs().endOf("week");
}

export function getTodayAsISODate() {
  return dayjs().format(DaylightDateFormat.ISO_DATE);
}

/**
 * Gets time difference in formats:
 * - 1h 30min
 * - 1h (if minutes are 0)
 * - 30min (if hours are 0)
 * @param start
 * @param end
 * @returns string with formatted time difference
 */
export function getFormattedTimeDifference(
  start: dayjs.Dayjs,
  end: dayjs.Dayjs
): string {
  // Round minutes up if seconds are greater than 30
  if (end.second() >= 30) end = end.add(1, "minute");
  if (start.second() >= 30) start = start.add(1, "minute");

  const duration = end.diff(start, "minute");
  if (duration < 60) {
    return `${duration}min`;
  }
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (minutes === 0) {
    return `${hours}h`;
  }
  return `${hours}h ${minutes}min`;
}

/**
 * Gets full datetime object from date and time:
 * @param date - Dayjs object
 * @param time - time string formatted as HH:MM
 * @returns Dayjs object with time
 */
export function formatDateGivenTime(
  date: Dayjs | null,
  time: string
): Dayjs | null {
  if (!date) return null;
  if (!time) time = "00:00";

  if (!timeSchema.safeParse(time).success) {
    throw new Error("Invalid time format");
  }

  const [hours, minutes] = time.split(":").map(Number);

  const resultDate = date.hour(hours).minute(minutes);

  return resultDate;
}

export function getHourOfDay(date: Dayjs) {
  return date.format("h:mm A");
}
