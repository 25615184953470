import dayjs from "dayjs";
import {
  Stop,
  Photo,
  StopShipmentNotes,
  SuggestedSic,
  Shipment,
} from "../../hooks/react-query/dispatch/useStops";
import { getBusinessDayStart } from "../mockUtils";
import { tagNameToShipmentTagMap } from "../../types/shipmentTag.type";

export const mockStopShipment: Shipment = {
  id: 1,
  proNumber: "123451587",
  pallets: 2,
  pieces: 10,
  weight: 200,
  status: "DISP",
  shipmentTags: tagNameToShipmentTagMap(["UC", "INDL"]),
};

const pendingMixedPickupsAndDeliveries: Stop = {
  id: 1,
  routeId: 1,
  routeName: "Route 1",
  driver: "John Doe",
  order: 5,
  name: "Customer Name XYZ",
  status: "pending",
  address: "1234 Main St, Destination A",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  arrivedAt: null,
  departedAt: null,
  coordinates: {
    latitude: 34.2122,
    longitude: -117.6437,
  },
  eta: getBusinessDayStart().add(1, "hour"),
  appointment: getBusinessDayStart().add(1, "hour").add(10, "minutes"),
  openTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(10, "hours"),
  pickups: [
    mockStopShipment,
    {
      id: 12,
      proNumber: "151289287",
      pallets: 2,
      pieces: 10,
      weight: 200,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["UC", "WC"]),
    },
    {
      id: 123,
      proNumber: "1234515874",
      pallets: 2,
      pieces: 10,
      weight: 200,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["$$$"]),
    },
    {
      id: 14,
      proNumber: "1512892873",
      pallets: 2,
      pieces: 10,
      weight: 200,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["OSAD"]),
    },
    {
      id: 13,
      proNumber: "P8415263",
      pallets: 2,
      pieces: 10,
      weight: 200,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["WC"]),
    },
    {
      id: 124,
      proNumber: "1512892871",
      pallets: 2,
      pieces: 10,
      weight: 200,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["WC"]),
    },
  ],
  deliveries: [
    {
      id: 2,
      proNumber: "543211587",
      pallets: 212,
      pieces: 611,
      weight: 126,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["$$$", "MOCK"]),
    },
    {
      id: 11,
      proNumber: "5432121587",
      pallets: 1,
      pieces: 5,
      weight: 100,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["APTM", "$$$"]),
    },
    {
      id: 9,
      proNumber: "5432115871",
      pallets: 1,
      pieces: 5,
      weight: 100,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["APTM"]),
    },
    {
      id: 19,
      proNumber: "54321215871",
      pallets: 1,
      pieces: 5,
      weight: 100,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["UC"]),
    },
  ],
  bills: 10,
  pallets: 3,
  pieces: 15,
  weight: 300,
  pickupBills: 6,
  pickupPallets: 2,
  pickupPieces: 10,
  pickupWeight: 200,
  deliveryBills: 4,
  deliveryPallets: 3,
  deliveryPieces: 5,
  deliveryWeight: 426,
  shipmentTags: tagNameToShipmentTagMap(["UC", "WC", "APTM", "$$$"]),
  filters:
    "123451587_151289287_1234515874_1512892873_P8415263_1512892871_Route 1_Customer Name XYZ_1234 Main St_Los Angeles_John Doe_$$$_MOCK_APTM_WC",
};

const pendingOnlyDeliveries: Stop = {
  id: 2,
  routeId: 1,
  routeName: "Route 1",
  driver: "John Doe",
  order: 4,
  name: "Another Customer",
  status: "pending",
  address: "5678 Secondary St, Destination A",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  arrivedAt: null,
  departedAt: null,
  coordinates: {
    latitude: 34.1122,
    longitude: -117.5437,
  },
  eta: getBusinessDayStart().add(2, "hours"),
  openTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(10, "hours"),
  pickups: [],
  deliveries: [
    {
      id: 2,
      proNumber: "678901587",
      pallets: 1,
      pieces: 2,
      weight: 50,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["UC"]),
    },
  ],
  bills: 1,
  pallets: 1,
  pieces: 2,
  weight: 50,
  pickupBills: 0,
  pickupPallets: 0,
  pickupPieces: 0,
  pickupWeight: 0,
  deliveryBills: 1,
  deliveryPallets: 1,
  deliveryPieces: 2,
  deliveryWeight: 50,
  shipmentTags: tagNameToShipmentTagMap(["UC"]),
  filters:
    "678901587_Route 1_Another Customer_5678 Secondary St_Los Angeles_John Doe_UC",
};

const unassigned: Stop = {
  id: 4,
  name: "Mock Customer With a Name That Requires Tooltip",
  status: "unassigned",
  address:
    "9012 Tertiary This is a Really Long Mocked Address That helps Test Tooltips, Wrapping and Ellipsis Rd, Destination B",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  coordinates: {
    latitude: 34.1522,
    longitude: -117.4437,
  },
  eta: getBusinessDayStart().add(2, "hours"),
  arrivedAt: getBusinessDayStart().add(2, "hours"),
  departedAt: getBusinessDayStart().add(2, "hours").add(30, "minutes"),
  openTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(12, "hours"),
  pickups: [
    {
      id: 3,
      proNumber: "098761587",
      pallets: 3,
      pieces: 15,
      weight: 300,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["DTCL", "CC"]),
    },
  ],
  deliveries: [],
  bills: 4,
  pallets: 3,
  pieces: 15,
  weight: 300,
  pickupBills: 4,
  pickupPallets: 3,
  pickupPieces: 15,
  pickupWeight: 300,
  deliveryBills: 0,
  deliveryPallets: 0,
  deliveryPieces: 0,
  deliveryWeight: 0,
  shipmentTags: tagNameToShipmentTagMap(["DTCL", "CC"]),
  filters:
    "098761587_Mock Customer With a Name That Requires Tooltip_9012 Tertiary This is a Really Long Mocked Address That helps Test Tooltips_Los Angeles_DTCL_CC",
};

const completed: Stop = {
  id: 5,
  routeId: 5,
  routeName: "Route 2",
  driver: "Jane Doe",
  order: 2,
  name: "Customer Name ABC",
  status: "completed",
  address: "3456 Quaternary Blvd, Destination B",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  eta: null,
  coordinates: {
    latitude: 34.0522,
    longitude: -117.2437,
  },
  arrivedAt: getBusinessDayStart().add(2, "hours"),
  departedAt: getBusinessDayStart().add(2, "hours").add(30, "minutes"),
  openTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(12, "hours"),
  pickups: [
    {
      id: 2,
      proNumber: "654321587",
      pallets: 2,
      pieces: 10,
      weight: 150,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["OSAD", "WC"]),
    },
  ],
  deliveries: [],
  bills: 2,
  pallets: 2,
  pieces: 10,
  weight: 150,
  pickupBills: 2,
  pickupPallets: 2,
  pickupPieces: 10,
  pickupWeight: 150,
  deliveryBills: 0,
  deliveryPallets: 0,
  deliveryPieces: 0,
  deliveryWeight: 0,
  shipmentTags: tagNameToShipmentTagMap(["OSAD", "WC"]),
  filters:
    "654321587_Route 2_Customer Name ABC_3456 Quaternary Blvd_Los Angeles_Jane Doe_OSAD_WC",
};

const pendingOnlyPickups: Stop = {
  id: 6,
  routeId: 6,
  routeName: "LBY-1",
  driver: "Mike O'Connor",
  order: 4,
  name: "Customer Alpha",
  status: "pending",
  address: "101 Main St, Destination F",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  coordinates: {
    latitude: 34.0322,
    longitude: -117.3437,
  },
  eta: getBusinessDayStart().add(30, "minutes"),
  arrivedAt: getBusinessDayStart().add(1, "hour"),
  departedAt: getBusinessDayStart().add(1, "hour").add(15, "minutes"),
  openTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(9, "hours"),
  pickups: [
    {
      id: 101,
      proNumber: "1234510001",
      pallets: 1,
      pieces: 5,
      weight: 150,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["APTM", "UC"]),
    },
  ],
  deliveries: [],
  bills: 2,
  pallets: 1,
  pieces: 5,
  weight: 150,
  pickupBills: 2,
  pickupPallets: 1,
  pickupPieces: 5,
  pickupWeight: 150,
  deliveryBills: 0,
  deliveryPallets: 0,
  deliveryPieces: 0,
  deliveryWeight: 0,
  shipmentTags: tagNameToShipmentTagMap(["APTM", "UC"]),
  filters:
    "1234510001_LBY-1_Customer Alpha_101 Main St_Los Angeles_Mike O'Connor_APTM_UC",
};

const incomplete: Stop = {
  id: 8,
  routeId: 6,
  routeName: "LBY-1",
  driver: "Mike O'Connor",
  order: 3,
  name: "Customer Gamma",
  status: "incomplete",
  address: "303 Pine St, Destination F",
  state: "CA",
  city: "Los Angeles",
  zipCode: "77077",
  arrivedAt: null,
  departedAt: null,
  coordinates: {
    latitude: 34.0522,
    longitude: -117.1637,
  },
  eta: getBusinessDayStart().add(3, "hours"),
  openTime: getBusinessDayStart(),
  closeTime: getBusinessDayStart().add(10, "hours"),
  pickups: [],
  deliveries: [
    {
      id: 103,
      proNumber: "1234510003",
      pallets: 3,
      pieces: 10,
      weight: 300,
      status: "DISP",
      shipmentTags: tagNameToShipmentTagMap(["OSAD", "UC"]),
    },
  ],
  bills: 4,
  pallets: 3,
  pieces: 10,
  weight: 300,
  pickupBills: 0,
  pickupPallets: 0,
  pickupPieces: 0,
  pickupWeight: 0,
  deliveryBills: 4,
  deliveryPallets: 3,
  deliveryPieces: 10,
  deliveryWeight: 300,
  shipmentTags: tagNameToShipmentTagMap(["OSAD", "UC"]),
  filters:
    "1234510003_LBY-1_Customer Gamma_303 Pine St_Los Angeles_Mike O'Connor_OSAD_UC",
};

export const mockDispatchStops = {
  pendingMixedPickupsAndDeliveries: [pendingMixedPickupsAndDeliveries],
  pendingOnlyDeliveries: [pendingOnlyDeliveries],
  unassigned: [unassigned],
  completed: [completed],
  pendingOnlyPickups: [pendingOnlyPickups],
  incomplete: [incomplete],
  empty: [],
  default: [
    pendingMixedPickupsAndDeliveries,
    pendingOnlyDeliveries,
    {
      id: 3,
      routeId: 1,
      routeName: "Route 1",
      driver: "John Doe",
      order: 6,
      name: "Customer ABC XYZ",
      status: "pending",
      address: "5679 Secondary St, Destination A",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77077",
      arrivedAt: null,
      departedAt: null,
      coordinates: {
        latitude: 33.9522,
        longitude: -117.2837,
      },
      eta: getBusinessDayStart().add(4, "hours"),
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(2, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 2,
          proNumber: "678901587",
          pallets: 1,
          pieces: 2,
          weight: 50,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC"]),
        },
      ],
      bills: 1,
      pallets: 1,
      pieces: 2,
      weight: 50,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 1,
      deliveryPallets: 1,
      deliveryPieces: 2,
      deliveryWeight: 50,
      shipmentTags: tagNameToShipmentTagMap(["UC"]),
      filters:
        "678901587_Route 1_Customer ABC XYZ_5679 Secondary St_Los Angeles_John Doe_UC",
    },
    {
      id: 14,
      routeId: 1,
      routeName: "Route 1",
      driver: "John Doe",
      order: 9,
      name: "Customer DEF",
      status: "pending",
      address: "4609 Arlington Ave",
      state: "CA",
      city: "Riverside",
      zipCode: "92504",
      arrivedAt: null,
      departedAt: null,
      coordinates: {
        latitude: 33.946864,
        longitude: -117.403303,
      },
      eta: getBusinessDayStart().add(4, "hours"),
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(2, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 2,
          proNumber: "678901587",
          pallets: 1,
          pieces: 2,
          weight: 50,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC"]),
        },
      ],
      bills: 1,
      pallets: 1,
      pieces: 2,
      weight: 50,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 1,
      deliveryPallets: 1,
      deliveryPieces: 2,
      deliveryWeight: 50,
      shipmentTags: tagNameToShipmentTagMap(["UC"]),
      filters:
        "678901587_Route 1_Customer ABC XYZ_5679 Secondary St_Los Angeles_John Doe_UC",
    },
    unassigned,
    completed,
    pendingOnlyPickups,
    {
      id: 7,
      routeId: 6,
      routeName: "LBY-1",
      driver: "Mike O'Connor",
      order: 2,
      name: "Customer Beta",
      status: "completed",
      address: "202 Oak St, Destination F",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77077",
      coordinates: {
        latitude: 34.0422,
        longitude: -117.2437,
      },
      eta: getBusinessDayStart().add(2, "hours"),
      arrivedAt: getBusinessDayStart().add(2, "hours").add(10, "minutes"),
      departedAt: getBusinessDayStart().add(2, "hours").add(35, "minutes"),
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(10, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 102,
          proNumber: "1234510002",
          pallets: 2,
          pieces: 8,
          weight: 220,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["CC", "UC"]),
        },
      ],
      bills: 3,
      pallets: 2,
      pieces: 8,
      weight: 220,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 3,
      deliveryPallets: 2,
      deliveryPieces: 8,
      deliveryWeight: 220,
      shipmentTags: tagNameToShipmentTagMap(["CC", "UC"]),
      filters:
        "1234510002_LBY-1_Customer Beta_202 Oak St_Los Angeles_Mike O'Connor_CC_UC",
    },
    incomplete,
    {
      id: 9,
      routeId: 7,
      routeName: "ONT-2",
      driver: "Emily Clarke",
      order: 7,
      name: "Customer Delta",
      status: "pending",
      address: "404 Birch St, Destination G",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77078",
      coordinates: {
        latitude: 34.0622,
        longitude: -117.3237,
      },
      eta: getBusinessDayStart().add(1, "hour"),
      arrivedAt: getBusinessDayStart().add(1, "hour").add(10, "minutes"),
      departedAt: getBusinessDayStart().add(1, "hour").add(40, "minutes"),
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(9, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 104,
          proNumber: "5432112001",
          pallets: 1,
          pieces: 4,
          weight: 150,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC", "APTM"]),
        },
      ],
      bills: 2,
      pallets: 1,
      pieces: 4,
      weight: 150,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 2,
      deliveryPallets: 1,
      deliveryPieces: 4,
      deliveryWeight: 150,
      shipmentTags: tagNameToShipmentTagMap(["UC", "APTM"]),
      filters:
        "5432112001_ONT-2_Customer Delta_404 Birch St_Los Angeles_Emily Clarke_UC_APTM",
    },
    {
      id: 10,
      routeId: 7,
      routeName: "ONT-2",
      driver: "Emily Clarke",
      order: 2,
      name: "Customer Epsilon",
      status: "incomplete",
      address: "505 Cedar St, Destination G",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77078",
      arrivedAt: null,
      departedAt: null,
      coordinates: {
        latitude: 34.0722,
        longitude: -117.2037,
      },
      eta: getBusinessDayStart().add(2, "hours"),
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(10, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 105,
          proNumber: "5432112002",
          pallets: 2,
          pieces: 6,
          weight: 250,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
        },
      ],
      bills: 3,
      pallets: 2,
      pieces: 6,
      weight: 250,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 3,
      deliveryPallets: 2,
      deliveryPieces: 6,
      deliveryWeight: 250,
      shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
      filters:
        "5432112002_ONT-2_Customer Epsilon_505 Cedar St_Los Angeles_Emily Clarke_UC_CC",
    },
    {
      id: 11,
      name: "Unassigned Customer",
      status: "unassigned",
      address: "606 Maple St, Destination X",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77079",
      eta: null,
      arrivedAt: null,
      departedAt: null,
      coordinates: {
        latitude: 34.0822,
        longitude: -118.0237,
      },
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(12, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 106,
          proNumber: "5432112003",
          pallets: 1,
          pieces: 4,
          weight: 150,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["OSAD"]),
        },
      ],
      bills: 2,
      pallets: 1,
      pieces: 4,
      weight: 150,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 2,
      deliveryPallets: 1,
      deliveryPieces: 4,
      deliveryWeight: 150,
      shipmentTags: tagNameToShipmentTagMap(["OSAD"]),
      filters: "5432112003_Unassigned Customer_606 Maple St_Los Angeles_OSAD",
    },
    {
      id: 12,
      routeId: 7,
      routeName: "ONT-2",
      driver: "Emily Clarke",
      order: 2,
      name: "Customer Epsilon",
      status: "incomplete",
      address: "606 Maple St, Destination X",
      state: "CA",
      city: "Los Angeles",
      zipCode: "77078",
      arrivedAt: null,
      departedAt: null,
      coordinates: {
        latitude: 34.0822,
        longitude: -118.0237,
      },
      eta: getBusinessDayStart().add(2, "hours"),
      openTime: getBusinessDayStart(),
      closeTime: getBusinessDayStart().add(10, "hours"),
      pickups: [],
      deliveries: [
        {
          id: 105,
          proNumber: "5432112002",
          pallets: 2,
          pieces: 6,
          weight: 250,
          status: "DISP",
          shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
        },
      ],
      bills: 3,
      pallets: 2,
      pieces: 6,
      weight: 250,
      pickupBills: 0,
      pickupPallets: 0,
      pickupPieces: 0,
      pickupWeight: 0,
      deliveryBills: 3,
      deliveryPallets: 2,
      deliveryPieces: 6,
      deliveryWeight: 250,
      shipmentTags: tagNameToShipmentTagMap(["UC", "CC"]),
      filters:
        "5432112002_ONT-2_Customer Epsilon_606 Maple St_Los Angeles_Emily Clarke_UC_CC",
    },
  ],
} as const satisfies Record<string, Stop[]>;

export const mockDispatchPhotos = {
  default: [
    {
      imageSrc: "/empty-truck.png",
      createdAt: dayjs("2023-09-09"),
    },
    {
      imageSrc: "/dylt-logo-header.png",
      createdAt: dayjs("2023-09-10"),
    },
  ],
  empty: [],
} as const satisfies Record<string, Photo[]>;

export const mockDispatchStopShipmentNotes = {
  default: [
    {
      id: 10,
      notes:
        "This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen.",
    },
    {
      id: 11,
      notes:
        "This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment.",
    },
    {
      id: 12,
      notes:
        "This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen. This shipment is a mock shipment with a lot of notes. Very long notes. Extremely long notes. Notes that are so long that they are longer than the longest notes you have ever seen.",
    },
    {
      id: 13,
      notes: "This shipment is a short mock shipment with short notes.",
    },
    {
      id: 14,
      notes:
        "This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment. This shipment is a mock shipment with a lot of notes. But not as many notes as the other shipment.",
    },
  ],
} as const satisfies Record<string, StopShipmentNotes[]>;

export const mockDispatchStopSuggestedSics = {
  default: [
    {
      sicId: 2,
      code: "LAX",
      name: "Los Angeles",
      totalStops: 101,
      totalRoutes: 13,
    },
    {
      sicId: 1,
      code: "EWR",
      name: "New York",
      totalStops: 100,
      totalRoutes: 12,
    },
    {
      sicId: 111,
      code: "ONT",
      name: "Ontario",
      totalStops: 99,
      totalRoutes: 11,
    },
  ],
} as const satisfies Record<string, SuggestedSic[]>;
