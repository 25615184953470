import {
  BaseSelectProps,
  ListSubheader,
  MenuItem,
  Select,
} from "@mui/material";
import { Trap } from "../../types/planning/plannedTrap.type";
import DropdownStatusItem from "./DropdownStatusItem";

type SelectTrapProps = {
  label: string;
  onTrapChange: (trapId: number) => void;
  traps: Trap[];
} & BaseSelectProps<number>;

const SelectTrap = ({
  value,
  label,
  onTrapChange,
  traps = [],
  ...rest
}: SelectTrapProps) => {
  const plannedTraps = traps.filter(({ type }) => type === "Planned");
  const existingTraps = traps.filter(({ type }) => type !== "Planned");

  return (
    <Select
      {...rest}
      value={value}
      fullWidth
      required
      label={label}
      onChange={(e) => onTrapChange(Number(e.target.value))}
    >
      <ListSubheader>Existing Traps</ListSubheader>
      {existingTraps.length === 0 && <MenuItem disabled>None</MenuItem>}
      {existingTraps.map((trap) => (
        <MenuItem key={trap.id} value={trap.id}>
          <DropdownStatusItem label={trap.name} status="TRAP" />
        </MenuItem>
      ))}
      <ListSubheader>Planned Traps</ListSubheader>
      {plannedTraps.length === 0 && <MenuItem disabled>None</MenuItem>}
      {plannedTraps.map((trap) => (
        <MenuItem key={trap.id} value={trap.id}>
          <DropdownStatusItem
            label={trap.name}
            status={trap.status}
            bgColor="green"
            color="white"
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectTrap;
