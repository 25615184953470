import { DoorAssets } from "../../../../hooks/react-query/outbound/freightDetails/useGetDoorAssets";

export const doorAssets = {
  default: {
    doors: [
      {
        doorNumber: 7,
        doorType: 1,
        loadPriority: null,
        exclusiveForShipmentTags: true,
        assignedTags: [
          {
            color: "#777DE9",
            description: "Appointment Required ",
            id: 4,
            name: "APTR",
          },
          {
            id: 2,
            name: "UC",
            color: "#FBC02D",
            description: "Urgent care shipment",
          },
        ],
        pulls: [
          {
            plannedPullId: 25457,
            cutTime: "10:30 AM",
            carrierId: 16813,
            carrier: "MARSA TRANSPORTATION INC",
            tripNumber: 3099732,
            trailerNumber: "DYLT170409",
            trailerEquipmentId: 3401,
            trapId: null,
            trapName: "",
            isLoadAndHold: false,
            status: "EMTY",
            trailerStatus: "EMTY",
          },
          {
            plannedPullId: 25571,
            cutTime: "10:30 AM",
            carrierId: 16813,
            carrier: "MARSA TRANSPORTATION INC",
            tripNumber: 3099732,
            trailerNumber: "C244-2222",
            trailerEquipmentId: 28113,
            trapId: null,
            trapName: "",
            isLoadAndHold: false,
            status: "PLAN",
            trailerStatus: "EMTY",
          },
        ],
      },
      {
        doorNumber: 5,
        doorType: 1,
        loadPriority: null,
        exclusiveForShipmentTags: false,
        assignedTags: [
          {
            color: "#777DE9",
            description: "Appointment Required ",
            id: 4,
            name: "APTR",
          },
          {
            id: 2,
            name: "UC",
            color: "#FBC02D",
            description: "Urgent care shipment",
          },
        ],
        pulls: [
          {
            plannedPullId: 25572,
            cutTime: "10:30 AM",
            carrierId: 16813,
            carrier: "MARSA TRANSPORTATION INC",
            tripNumber: 3099732,
            trailerNumber: "DAL010",
            trailerEquipmentId: 2976,
            trapId: null,
            trapName: "",
            isLoadAndHold: false,
            status: "EMTY",
            trailerStatus: "EMTY",
          },
          {
            plannedPullId: 25573,
            cutTime: "10:30 AM",
            carrierId: 16813,
            carrier: "MARSA TRANSPORTATION INC",
            tripNumber: 3099732,
            trailerNumber: "DALGHOST03",
            trailerEquipmentId: 3263,
            trapId: null,
            trapName: "",
            isLoadAndHold: false,
            status: "EMTY",
            trailerStatus: "EMTY",
          },
        ],
      },
    ],
  },
} as const satisfies Record<string, DoorAssets>;
