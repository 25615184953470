import { memo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Business, MenuBook, Route, ViewModule } from "@mui/icons-material";
import { DragDropContext } from "@hello-pangea/dnd";
import { SidebarLayout } from "../../../components/shared/layout/SidebarLayout";
import {
  SelectionContextProvider,
  useSelectionContext,
} from "../../../context/SelectionContext";
import { AssignToOrigin } from "../../../services/prePlanningService.types";
import { useAssignShipments } from "../../../hooks/react-query/preplanning/dialogs/useAssignShipments";
import { dateToInt } from "../../../utils/dateTimeHelper";
import { toast } from "../../../utils/snackbarHelper";
import { toastMessage } from "../../../constants/strings";
import { ActionsMenuProvider } from "../shared/ActionsMenuContext";
import { SidebarContent } from "../shared/sidebar/Sidebar";
import { FixedSidebar } from "../../../components/shared/sidebar/Sidebar";
import {
  TableSelectionContextProvider,
  useTableSelectionContext,
} from "../../../components/shared/old-table/TableSelectionContext";
import { parseDroppableId } from "../../../utils/dragging";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { useDateSearchParamOrFallbackToToday } from "../../../hooks/useDateSearchParamOrFallbackToToday";
import { FlexColumn } from "../../../components/shared/layout/Flex";
import { useAllRoutesTable } from "../../../hooks/react-query/useDeliveryRoutes";
import ErrorDialog from "../../../components/shared/ErrorDialog";
import { ToggleViewGroupOption } from "../../../components/shared/ToggleViewGroup";
import { useIsSelectedDateTodayOrInTheFutureForCurrentSic } from "../../../hooks/useIsSelectedDateTodayOrInTheFutureForCurrentSic";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { useTableSelectionParam } from "../../../hooks/useTableSelectionParam";

export const prePlanningViewOptions = (
  isRouteAndSectionEnabled = false
): ToggleViewGroupOption[] => {
  const options: ToggleViewGroupOption[] = [];
  if (isRouteAndSectionEnabled) {
    options.push(
      {
        tab: "routes",
        icon: <Route />,
      },
      {
        tab: "sections",
        icon: <Business />,
      }
    );
  }
  options.push(
    {
      tab: "traps",
      icon: <ViewModule />,
    },
    {
      tab: "appointments",
      icon: <MenuBook />,
    }
  );

  return options;
};

const InboundSidebar = () => (
  <FixedSidebar title="Pre Planning" width={320}>
    <SidebarContent tab="pre-planning" />
  </FixedSidebar>
);

const DragDropContent = memo(() => {
  const isEditingEnabled = useIsSelectedDateTodayOrInTheFutureForCurrentSic();
  const isNewTableEnabled = useIsFeatureFlagEnabled(
    "inbound-new-table-design-client"
  );
  const { selection } = useTableSelectionParam();
  const [serviceCenter] = useSelectedServiceCenter();
  const [date] = useDateSearchParamOrFallbackToToday();
  const [dragAndDropErrorMessage, setDragAndDropErrorMessage] = useState("");

  const {
    selectedRows: shipmentsTable,
    clearSelection: clearSelectionOnOuterTable,
  } = useTableSelectionContext();

  const {
    selected: selectedShipmentsOnSidebar,
    clearSelection,
    setDraggingSource,
  } = useSelectionContext();

  const assignShipments = useAssignShipments({
    onSuccess: ({ affectedShipmentsCount }) => {
      clearSelection();
      toast(toastMessage.inbound.assign.success(affectedShipmentsCount), {
        variant: "success",
      });
      clearSelectionOnOuterTable();
    },
  });

  function getOrigins(
    sourceDroppableId: string,
    draggableId: string
  ): AssignToOrigin[] {
    const { id, type, source } = parseDroppableId(sourceDroppableId);
    if (isNewTableEnabled && source === "shipmentTable") {
      return [
        {
          id,
          shipmentIds:
            selection.map(Number).length > 1
              ? selection.map(Number)
              : [Number(draggableId)],
          type,
        },
      ];
    } else if (source === "mainTable" && shipmentsTable.shipments.length > 0) {
      const shipmentsGroupedByParentId = shipmentsTable.shipments.reduce(
        (grouped: AssignToOrigin[], shipment) => {
          if (!shipment.parentId) {
            throw new Error(`parentId for ${id} was unexpectedly not defined`);
          }

          const { parentId } = shipment;
          const existingGroup = grouped.find((group) => group.id === parentId);

          if (existingGroup) {
            existingGroup.shipmentIds.push(shipment.id);
          } else {
            grouped.push({
              id: parentId,
              shipmentIds: [shipment.id],
              type,
            });
          }

          return grouped;
        },
        []
      );

      return shipmentsGroupedByParentId;
    } else if (source === "sidebar" && selectedShipmentsOnSidebar.length > 0) {
      return selectedShipmentsOnSidebar.map((shipment) => ({
        id: shipment.id.toString(),
        shipmentIds: shipment.shipmentIds,
        type: shipment.type,
      }));
    } else {
      return [
        {
          id,
          shipmentIds: [Number(draggableId)],
          type,
        },
      ];
    }
  }

  const { data: allRoutes = [] } = useAllRoutesTable({
    date: dateToInt(date),
    sicId: serviceCenter.id,
  });

  return (
    <DragDropContext
      onDragStart={(test) => {
        if (!isEditingEnabled) return;
        setDraggingSource(
          test.source.droppableId.startsWith("sidebar")
            ? "sidebar"
            : "mainTable"
        );
      }}
      onDragEnd={(result) => {
        if (!isEditingEnabled) return;
        setDraggingSource("notDragging");
        // If droppableId is undefined, then the user dropped on an invalid destination,
        // so there's nothing to do here. Just return early.
        if (!result.destination?.droppableId) return;
        // Return early if the drop source is the same as the destination
        if (result.destination.droppableId === result.source.droppableId) {
          return;
        }
        const destDroppable = parseDroppableId(result.destination.droppableId);
        if (destDroppable.type === "bucket") {
          toast(toastMessage.inbound.assign.moveToBucketNotAllowed, {
            variant: "error",
          });
        }
        const draggableId = result.draggableId.includes("-")
          ? result.draggableId.split("-")[0]
          : result.draggableId;
        if (destDroppable.type === "route") {
          const route = allRoutes.find(
            (route) => route.id === parseInt(destDroppable.id)
          );
          if (!route) {
            throw new Error(
              `Route/trap with id ${destDroppable.id} not found while drag and dropping.`
            );
          }
        }
        assignShipments.mutate({
          target: {
            id: destDroppable.id,
            type: destDroppable.type,
            date: dateToInt(date),
            sicId: serviceCenter.id,
          },
          origins: getOrigins(result.source.droppableId, draggableId),
        });
        setDragAndDropErrorMessage("");
      }}
    >
      <SidebarLayout sidebar={<InboundSidebar />}>
        <FlexColumn style={{ padding: 24 }}>
          <Outlet />
          <ErrorDialog
            open={Boolean(dragAndDropErrorMessage)}
            errorMessage={dragAndDropErrorMessage}
            setOpen={(value) => {
              if (!value) setDragAndDropErrorMessage("");
            }}
          />
        </FlexColumn>
      </SidebarLayout>
    </DragDropContext>
  );
});
DragDropContent.displayName = "DragDropContent";

/** @public */
export const Component = () => (
  <SelectionContextProvider>
    <TableSelectionContextProvider>
      <ActionsMenuProvider>
        <DragDropContent />
      </ActionsMenuProvider>
    </TableSelectionContextProvider>
  </SelectionContextProvider>
);
