import { Shipment } from "../../services/prePlanningService.types";
import { BaseQueryParams } from "../../types/filter";
import {
  filterTags,
  getTagsFilter,
  TagsQueryParam,
} from "./commonFilters/tagsFilter";
import {
  ServiceDueDateQueryParam,
  filterServiceDue,
  getServiceDueFilter,
} from "./commonFilters/serviceDueDateFilter";
import {
  filterPresetRouteName,
  getPresetRouteNameFilter,
  PresetRouteNameQueryParam,
} from "./commonFilters/presetRouteFilter";
import {
  AppointmentDateQueryParam,
  filterAppointmentDate,
  getAppointmentDateFilter,
} from "./commonFilters/appointmentDateFilter";
import {
  DestinationSICQueryParam,
  filterDestinationSIC,
  getDestinationSicFilter,
} from "./commonFilters/destinationSICFilter";
import {
  ConsigneeZipQueryParam,
  filterConsigneeZip,
  getConsigneeZipFilter,
} from "./commonFilters/consigneeZipFilter";
import {
  ConsigneeCityQueryParam,
  filterConsigneeCity,
  getConsigneeCityFilter,
} from "./commonFilters/consigneeCityFilter";
import {
  ConsigneeAddressQueryParam,
  filterConsigneeAddress,
  getConsigneeAddressFilter,
} from "./commonFilters/consigneeAddressFilter";
import {
  ConsigneeNameQueryParam,
  filterConsigneeName,
  getConsigneeNameFilter,
} from "./commonFilters/consigneeNameFilter";
import {
  filterShipper,
  getShipperFilter,
  ShipperNameQueryParam,
} from "./commonFilters/shipperNameFilter";
import {
  filterStatus,
  getStatusFilter,
  StatusQueryParam,
} from "./commonFilters/statusFilter";
import { SummaryQueryParams } from "./summaryFilters";
import { BillsQueryParam } from "./commonFilters/billsFilter";
import { filterDataByInboundFilterURLParams } from "./utils";
import { ETAQueryParam, getETAFilter } from "./commonFilters/ETAFilter";
import { filterPallets, getPalletsFilter } from "./commonFilters/palletsFilter";
import { filterPieces, getPiecesFilter } from "./commonFilters/piecesFilter";
import { filterWeight, getWeightFilter } from "./commonFilters/weightFilter";

type ShipmentQueryParams =
  | Exclude<SummaryQueryParams, BillsQueryParam>
  | StatusQueryParam
  | TagsQueryParam
  | ShipperNameQueryParam
  | ConsigneeNameQueryParam
  | ConsigneeAddressQueryParam
  | ConsigneeCityQueryParam
  | ConsigneeZipQueryParam
  | DestinationSICQueryParam
  | AppointmentDateQueryParam
  | ServiceDueDateQueryParam
  | ETAQueryParam
  | PresetRouteNameQueryParam;

type ShipmentFilters = Record<ShipmentQueryParams, string[] | undefined>;

function filterByEtaBefore(shipments: Shipment[], eta?: string[]) {
  if (!eta || !eta[0] || eta[0] === "All") return shipments;
  // Get the first eta param
  const filterDate = new Date(eta[0]);

  if (!(filterDate instanceof Date) || isNaN(filterDate.getTime())) {
    throw new Error("Invalid filterDate provided");
  }

  return shipments.filter((item) => {
    if (item.etaDate === null) {
      return false;
    }

    if (!(item.etaDate instanceof Date) || isNaN(item.etaDate.getTime())) {
      return false;
    }

    return item.etaDate.getTime() < filterDate.getTime();
  });
}

export const filterShipmentData = (
  shipments: Shipment[],
  {
    presetRouteName,
    serviceDueDate,
    appointmentDate,
    shipperName,
    consigneeName,
    consigneeAddress,
    consigneeCity,
    consigneeZip,
    destinationSicCode,
    status,
    tags,
    pieces,
    pallets,
    weight,
    eta,
  }: ShipmentFilters,
  timeZone: string
) => {
  let result = filterByEtaBefore(shipments, eta);
  result = filterPresetRouteName(result, presetRouteName);
  result = filterServiceDue(result, serviceDueDate);
  result = filterShipper(result, shipperName);
  result = filterConsigneeName(result, consigneeName);
  result = filterConsigneeAddress(result, consigneeAddress);
  result = filterConsigneeCity(result, consigneeCity);
  result = filterConsigneeZip(result, consigneeZip);
  result = filterDestinationSIC(result, destinationSicCode);
  result = filterAppointmentDate(result, timeZone, appointmentDate);
  result = filterPieces(result, pieces);
  result = filterPallets(result, pallets);
  result = filterWeight(result, weight);
  result = filterTags(result, tags);
  result = filterStatus(result, status);

  return result;
};

export const getShipmentTableFilters = (
  shipments: Shipment[],
  timeZone: string,
  searchParams: URLSearchParams
) => {
  const getShipments = (param: BaseQueryParams) =>
    filterDataByInboundFilterURLParams(searchParams, param, shipments);
  return [
    getPresetRouteNameFilter(getShipments("presetRouteName")),
    getETAFilter(getShipments("eta"), timeZone),
    getServiceDueFilter(getShipments("serviceDueDate")),
    getShipperFilter(getShipments("shipperName")),
    getConsigneeNameFilter(getShipments("consigneeName")),
    getConsigneeAddressFilter(getShipments("consigneeAddress")),
    getConsigneeCityFilter(getShipments("consigneeCity")),
    getConsigneeZipFilter(getShipments("consigneeZip")),
    getDestinationSicFilter(getShipments("destinationSicCode")),
    getAppointmentDateFilter(getShipments("appointmentDate"), timeZone),
    getPalletsFilter(getShipments("pallets")),
    getPiecesFilter(getShipments("pieces")),
    getWeightFilter(getShipments("weight")),
    getTagsFilter(getShipments("tags")),
    getStatusFilter(getShipments("status")),
  ];
};
