import { Box, Typography } from "@mui/material";
import { prePlanningViewOptions } from "../PrePlanning";
import { useIsFeatureFlagEnabled } from "../../../../featureFlags/useIsFeatureFlagEnabled";
import { PrePlanningToggleViewGroup } from "./PrePlanningToggleViewGroup";

interface SectionTitleProps {
  page: string;
  count: number;
  showToggles?: boolean;
}

const SectionTitle = ({
  page,
  count,
  showToggles = true,
}: SectionTitleProps) => {
  const isRoutesAndSectionsEnabled = useIsFeatureFlagEnabled(
    "inbound-routes-and-section-tabs"
  );
  return (
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="space-between"
      marginBottom="1.5rem"
    >
      <Box display="flex" alignItems="flex-end">
        <Typography
          marginRight="0.5rem"
          fontSize={32}
          fontWeight="400"
          lineHeight="42px"
        >
          {page}
        </Typography>
        <Typography
          fontSize="24px"
          fontWeight="500"
          lineHeight="38px"
          sx={{ opacity: 0.6 }}
        >
          ({count})
        </Typography>
      </Box>
      {showToggles && (
        <PrePlanningToggleViewGroup
          options={prePlanningViewOptions(isRoutesAndSectionsEnabled)}
        />
      )}
    </Box>
  );
};

export default SectionTitle;
