import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrls } from "../../utils/apiUrls";
import { http } from "../../utils/httpCommon";
import { toast } from "../../utils/snackbarHelper";
import { toastMessage } from "../../constants/strings";
import {
  RouteAssignmentResponse,
  TrapAssignmentResponse,
  routeAssignmentResponseSchema,
  trapAssignmentResponseSchema,
} from "../../types/planning/routeAssignmentResponse.type";
import { EditedRoute } from "./useDeliveryRoutes.types";
import { plannedRoutesQueryKey } from "./usePlannedRoutes";
import { deliveryRoutesKeys } from "./useDeliveryRoutes";

type RouteAssignmentProps = {
  date: number;
  sicId: number;
  searchTerm: string;
  onSuccess: () => Promise<void> | void;
};

interface RouteAssignmentDeleteProps extends RouteAssignmentProps {
  onSettled?: () => void;
}

type RouteAssignmentRequest = {
  planId: number;
  route: EditedRoute;
};

type DeleteRouteAssignmentRequest = {
  planIds: number[];
};

export const useRouteAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
}: RouteAssignmentProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ planId, route }: RouteAssignmentRequest) => {
      if (route.type === "delivery_route") {
        const { data } = await http.put<RouteAssignmentResponse>(
          apiUrls.putRouteAssignment(planId),
          route
        );
        return routeAssignmentResponseSchema.parse(data);
      } else {
        const { data } = await http.put<TrapAssignmentResponse>(
          apiUrls.putTrapAssignment(planId),
          route
        );
        return trapAssignmentResponseSchema.parse(data);
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      await onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
  });
};

export const useDeleteRouteAssignment = ({
  date,
  sicId,
  searchTerm,
  onSuccess,
  onSettled,
}: RouteAssignmentDeleteProps) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ planIds }: DeleteRouteAssignmentRequest) => {
      const { status } = await http.delete(
        apiUrls.deleteRouteAssignments(planIds.join(","))
      );
      return status;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: plannedRoutesQueryKey(date, sicId, searchTerm),
      });
      await queryClient.invalidateQueries({
        queryKey: deliveryRoutesKeys.deliveryRoutesSummary(sicId, date),
      });
      await onSuccess();
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      onSettled?.();
    },
  });
};
