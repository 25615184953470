import { TableBody as MuiTableBody } from "@mui/material";
import { Row, Table } from "@tanstack/react-table";
import { Fragment, useCallback, useRef } from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "@hello-pangea/dnd";
import { ContextMenuAction } from "../../../types/contextMenuAction";
import ContextMenuDropdown, { ContextMenuRef } from "../ContextMenuDropdown";
import { getDroppableStyleRow } from "../../../utils/dragging";
import RowError from "../old-table/RowError";
import TableRow from "./TableRow";
import { RowSelectionMode } from "./Selectable.types";
import { ErrorMap } from "./table.types";

type Props<TData> = {
  /** Table instance */
  table: Table<TData>;
  /** current mode of selection */
  rowSelectionMode?: RowSelectionMode;
  /** id of the droppable table */
  droppableTableId?: string;
  /** id of the droppable row */
  droppableRowId?: (row: Row<TData>) => string;
  /** context menu actions */
  contextMenuActions?: (row?: TData) => ContextMenuAction[];
  /** Map of row id to row error. */
  rowErrors?: ErrorMap;
};

const TableBody = <TData,>({
  table,
  rowSelectionMode = "unselectable",
  droppableTableId,
  droppableRowId,
  contextMenuActions,
  rowErrors,
}: Props<TData>) => {
  const rowError = (row: Row<TData>) => {
    const rowError = rowErrors?.get(Number(row.id));
    return rowError && <RowError rowId={row.id} {...rowError} />;
  };
  const contextMenuRef = useRef<ContextMenuRef<TData>>(null);

  const contextActions = useCallback(
    (row?: TData) => {
      if (contextMenuActions) {
        if (
          !table.getIsSomeRowsSelected() &&
          !table.getIsAllPageRowsSelected()
        ) {
          return contextMenuActions(row);
        } else {
          return contextMenuActions();
        }
      }
      return [];
    },
    [contextMenuActions, table]
  );

  const tableRowProps = (row: Row<TData>) => ({
    table,
    row,
    selectionCount: row.getIsSelected()
      ? table.getSelectedRowModel().flatRows.length
      : 0,
    rowSelectionMode,
    isSelected: row.getIsSelected(),
    contextMenuRef,
  });

  const tableRowDefinition = () => {
    if (droppableTableId) {
      return (
        <Droppable droppableId={droppableTableId} renderClone={null}>
          {(
            droppableProvided: DroppableProvided,
            snapshot: DroppableStateSnapshot
          ) => (
            <MuiTableBody
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
              sx={{ ...getDroppableStyleRow(snapshot.isDraggingOver) }}
            >
              {table.getRowModel().rows.map((row) => (
                <Fragment key={row.id}>
                  {rowError(row)}
                  <TableRow {...tableRowProps(row)} />
                </Fragment>
              ))}
              {contextMenuActions && (
                <ContextMenuDropdown
                  actions={contextActions}
                  ref={contextMenuRef}
                />
              )}
            </MuiTableBody>
          )}
        </Droppable>
      );
    } else if (droppableRowId) {
      return (
        <MuiTableBody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              {rowError(row)}
              <Droppable droppableId={droppableRowId(row)}>
                {(
                  droppableProvided: DroppableProvided,
                  snapshot: DroppableStateSnapshot
                ) => (
                  <TableRow
                    {...tableRowProps(row)}
                    droppableProvided={droppableProvided}
                    snapshot={snapshot}
                  />
                )}
              </Droppable>
            </Fragment>
          ))}
          {contextMenuActions && (
            <ContextMenuDropdown
              actions={contextActions}
              ref={contextMenuRef}
            />
          )}
        </MuiTableBody>
      );
    } else {
      return (
        <MuiTableBody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              {rowError(row)}
              <TableRow {...tableRowProps(row)} />
            </Fragment>
          ))}
          {contextMenuActions && (
            <ContextMenuDropdown
              actions={contextActions}
              ref={contextMenuRef}
            />
          )}
        </MuiTableBody>
      );
    }
  };

  return tableRowDefinition();
};

export default TableBody;
