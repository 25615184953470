import dayjs from "dayjs";
import { TripListTrip } from "../../hooks/react-query/linehaul/useLoadBoard";

export const loadTrips = {
  tripCount: 2,
  bills: 0,
  weight: 0,
  tripSummaries: [
    {
      loadBoardId: 3021063,
      tripNumber: 3025801,
      carrier: {
        id: 92278,
        name: "MARSA TRANSPORTATION INC",
        code: "M199",
        isActive: true,
      },
      originSic: {
        id: 114,
        code: "DAL",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      stops: [],
      employeeDrivers: [],
      destinationSic: {
        id: 115,
        code: "HTX",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      drivers: [],
      bookingNumber: "JOHN",
      trailerNumber: "",
      pickupDate: dayjs("2024-09-03T01:00:00"),
      gateTime: null,
      closeDate: null,
      bills: 0,
      weight: 0,
      tags: [],
      sealNumber: "",
      cubage: null,
      status: "LDDK",
      driverCheckedIn: false,
    },
    {
      loadBoardId: 3021063,
      tripNumber: 3025810,
      carrier: {
        id: 92278,
        name: "MARSA TRANSPORTATION INC",
        code: "M199",
        isActive: true,
      },
      originSic: {
        id: 114,
        code: "DAL",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      stops: [],
      employeeDrivers: [],
      destinationSic: {
        id: 115,
        code: "HTX",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      drivers: [],
      bookingNumber: "JOHN",
      trailerNumber: "",
      pickupDate: dayjs("2024-09-03T01:00:00"),
      gateTime: dayjs("2024-09-05T02:00:00"),
      closeDate: null,
      bills: 0,
      weight: 0,
      tags: [],
      sealNumber: "",
      cubage: null,
      status: "CLOS",
      driverCheckedIn: false,
    },
    {
      loadBoardId: 3021355,
      tripNumber: 3025969,
      carrier: {
        id: 92263,
        name: "MURILLO LOGISTIC INC",
        code: "M183",
        isActive: true,
      },
      originSic: {
        id: 114,
        code: "DAL",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      stops: [],
      destinationSic: {
        id: 115,
        code: "HTX",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      drivers: [],
      bookingNumber: "RALPH",
      trailerNumber: "",
      employeeDrivers: [],
      pickupDate: dayjs("2024-09-02T21:00:00"),
      gateTime: null,
      closeDate: null,
      bills: 0,
      weight: 0,
      tags: [],
      sealNumber: "",
      cubage: null,
      status: "PLAN",
      driverCheckedIn: true,
    },
    {
      loadBoardId: 3021344,
      tripNumber: 3025888,
      carrier: {
        id: 92263,
        name: "MURILLO LOGISTIC INC",
        code: "M183",
        isActive: true,
      },
      originSic: {
        id: 114,
        code: "DAL",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      stops: [],
      destinationSic: {
        id: 115,
        code: "HTX",
        ownership: "C",
        lhRegionId: 135,
        timeZone: "America/Chicago",
      },
      drivers: [],
      bookingNumber: "RALPH",
      trailerNumber: "",
      employeeDrivers: [],
      pickupDate: dayjs("2024-09-02T21:00:00"),
      gateTime: null,
      closeDate: null,
      driverCheckedIn: false,
      bills: 0,
      weight: 0,
      tags: [],
      sealNumber: "",
      cubage: null,
      status: "LDDK",
    },
  ] satisfies TripListTrip[],
};

export const trailers = [
  {
    id: 23872,
    trailerNumber: "HTXGHOST30",
  },
  {
    id: 23904,
    trailerNumber: "ONTGHOST16",
  },
  {
    id: 23958,
    trailerNumber: "IB TRAP 2",
  },
];
