import { AppBar, Divider, IconButton } from "@mui/material";
import { Menu, Settings } from "@mui/icons-material";
import Stack from "@mui/system/Stack";
import { Link as RouterLink } from "react-router-dom";
import UserMenu from "../../../pages/UserMenu";
import { useSelectedServiceCenter } from "../../../hooks/useSelectedServiceCenter";
import { DepartmentChildLink, DepartmentSelect } from "./DepartmentSelect";
import { ServiceCenterSelect } from "./ServiceCenterSelect";
import { useDepartments } from "./useDepartments";

export const GlobalNav = () => {
  const activeDepartment = useDepartments().find((d) => d.active);
  const [serviceCenter] = useSelectedServiceCenter();

  return (
    <AppBar
      position="static"
      variant="outlined"
      color="inherit"
      elevation={0}
      sx={{ height: 64, zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Stack direction="row" pl={2} height="100%" alignItems="center">
        <IconButton sx={{ mr: 1 }}>
          <Menu />
        </IconButton>
        {activeDepartment && (
          <>
            <DepartmentSelect value={activeDepartment} />
            <Divider flexItem sx={{ width: "1px", bgcolor: "divider" }} />
          </>
        )}
        {activeDepartment?.showServiceCenterSelect && (
          <>
            <ServiceCenterSelect />
            <Divider flexItem sx={{ width: "1px", bgcolor: "divider" }} />
          </>
        )}
        {activeDepartment && (
          <Stack direction="row" gap={3} ml={4} alignItems="center">
            {activeDepartment.childRoutes
              .filter((r) => r.enabled)
              .map((tab) => (
                <DepartmentChildLink key={tab.route} tab={tab} />
              ))}
          </Stack>
        )}
        <Stack flex={1} />
        <Stack direction="row" gap={1} pr={2} alignItems="center">
          {/* commented out until implemented */}
          {/* <TextField
            placeholder="Search"
            sx={{ width: 200, mr: 1 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}

          <IconButton
            component={RouterLink}
            to={`settings/${serviceCenter.code}`}
            aria-label="Settings"
          >
            <Settings />
          </IconButton>

          <UserMenu />
        </Stack>
      </Stack>
    </AppBar>
  );
};
