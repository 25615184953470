// CSpell:ignore CLDV DISP
import { Alert, Box } from "@mui/material";
import { LightTooltip } from "../../../components/shared/LightTooltip";

const ClosedStatusWarning = () => (
  <Alert severity="warning" sx={{ marginTop: "24px" }}>
    Moving shipments to or from any route/trap in a{" "}
    <LightTooltip title="CLDV, DISP, TRAP">
      <Box component="a">Closed</Box>
    </LightTooltip>{" "}
    status will change to a{" "}
    <LightTooltip title="CLDK">
      <Box component="a">Loading</Box>
    </LightTooltip>{" "}
    status.
  </Alert>
);

export default ClosedStatusWarning;
