// cSpell: ignore uccc
import { Navigate, RouteObject } from "react-router-dom";
import { WorkInProgress } from "../components/shared/WorkInProgress";
import { ErrorBoundary } from "../components/ErrorBoundary";

export const linehaulRoutes: RouteObject[] = [
  {
    path: "linehaul",
    lazy: () => import("../pages/linehaul/LinehaulLayout"),
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Navigate to="master-schedule" replace /> },
      {
        path: "load-board",
        errorElement: <ErrorBoundary />,
        lazy: () => import("../pages/linehaul/loadboard/LoadBoard"),
      },
      {
        path: "load-board-v2",
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            lazy: () => import("../pages/linehaul/loadboard/LinehaulOverview"),
          },
          {
            path: "reship/:sicCode",
            lazy: () => import("../pages/linehaul/reship/Reship"),
          },
          {
            path: "ucccPlanning",
            lazy: () => import("../pages/linehaul/loadboard/UcccPlanning"),
          },
          {
            path: ":originSicCode",
            lazy: () =>
              import("../pages/linehaul/loadboard/ServiceCenterOverview"),
          },
          {
            path: ":originSicCode/:laneDestinationSicCode",
            lazy: () => import("../pages/linehaul/loadboard/LaneOverview"),
          },
          {
            path: ":originSicCode/:laneDestinationSicCode/trip/:tripNumber",
            lazy: () => import("../pages/linehaul/loadboard/TripOverview"),
          },
        ],
      },
      {
        path: "master-schedule",
        errorElement: <ErrorBoundary />,
        lazy: () => import("../pages/linehaul/master-schedule/MasterSchedule"),
      },
      {
        path: "master-schedule-templates",
        errorElement: <ErrorBoundary />,
        children: [
          {
            index: true,
            lazy: () => import("../pages/linehaul/template/Templates"),
          },
          {
            path: ":templateId",
            lazy: () => import("../pages/linehaul/template/Template"),
          },
        ],
      },
      {
        path: "forecasting",
        errorElement: <ErrorBoundary />,
        element: <WorkInProgress />,
      },
      {
        path: "transit-dashboard",
        errorElement: <ErrorBoundary />,
        element: <WorkInProgress />,
      },
      {
        path: "carrier-pay",
        errorElement: <ErrorBoundary />,
        element: <WorkInProgress />,
      },
      {
        path: "equipment-inventory",
        errorElement: <ErrorBoundary />,
        element: <WorkInProgress />,
      },
    ],
  },
];
