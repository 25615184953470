import { IncompleteTrailerResponse } from "../../../hooks/react-query/useIncompleteTrailers";
import { defaultMockServiceCenter } from "../../serviceCenter/serviceCenters.mocks";

export const mockIncompleteTrailers: IncompleteTrailerResponse[] = [
  {
    type: "delivery_route",
    id: 30055,
    sic: defaultMockServiceCenter.code,
    door: 0,
    routeType: "Static",
    name: "AZUSA-1",
    title: "AZUSA-1",
    status: "CLDK",
    bills: 1,
    pallets: 1,
    weight: 500,
    pieces: 1,
    weightUnit: "lbs",
    tags: [
      {
        id: 6,
        name: "LG",
        description: "",
        colorHex: "#679CB2",
        position: 40,
      },
      {
        id: 77,
        name: "",
        description: "Inside Delivery",
        colorHex: "#9CA8E5",
        position: 90,
      },
    ],
    routeName: "AZUSA-1",
    zones: [""],
    zone: "",
    date: new Date(),
    startTime: new Date(),
    endTime: null,
    tractor: null,
    trailer: null,
    straightTruck: null,
    driver: null,
    shipmentIds: [131365190],
    shipmentsSummary: {
      allShipmentsPlanned: true,
      allShipmentsPlannedForFuture: true,
      someShipmentsPlanned: true,
      allFuturePlanDates: [1],
      shipmentsPieces: 0,
      shipmentsCount: 1,
      shipmentsPallets: 1,
      shipmentsWeight: 690,
      shipmentsWeightUnit: "lbs",
      shipmentIds: [131365190],
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
      ],
    },
    hasSearchTerm: false,
    location: "",
  },
  {
    type: "delivery_route",
    id: 30058,
    sic: defaultMockServiceCenter.code,
    door: 0,
    routeType: "Static",
    name: "CERRITOS-1",
    title: "CERRITOS-1",
    status: "CLDK",
    zone: "South LA County Coast",
    zones: [],
    date: new Date(),
    startTime: new Date(),
    bills: 1,
    pallets: 1,
    weight: 500,
    pieces: 1,
    weightUnit: "lbs",
    tags: [
      {
        id: 6,
        name: "LG",
        description: "",
        colorHex: "#679CB2",
        position: 40,
      },
      {
        id: 77,
        name: "",
        description: "Inside Delivery",
        colorHex: "#9CA8E5",
        position: 90,
      },
    ],
    routeName: "AZUSA-1",
    endTime: null,
    tractor: null,
    trailer: null,
    straightTruck: null,
    driver: null,
    shipmentIds: [131367485],
    shipmentsSummary: {
      allShipmentsPlanned: true,
      allShipmentsPlannedForFuture: true,
      someShipmentsPlanned: true,
      allFuturePlanDates: [0],
      shipmentsPieces: 0,
      shipmentsCount: 2,
      shipmentsPallets: 2,
      shipmentsWeight: 690,
      shipmentsWeightUnit: "lbs",
      shipmentIds: [131367485],
      shipmentsTags: [
        {
          id: 6,
          name: "LG",
          description: "Liftgate",
          colorHex: "#679CB2",
          position: 40,
        },
        {
          id: 77,
          name: "INDL",
          description: "Inside Delivery",
          colorHex: "#9CA8E5",
          position: 90,
        },
      ],
    },
    hasSearchTerm: false,
    location: "",
  },
];
