import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import { Box, Typography } from "@mui/material";
import { DraggableSelectionCounter } from "./old-table/DraggableSelectionCounter";
import DragIcon from "./icons/DragIcon";

interface DragMultipleShipmentsProps {
  draggableId: string;
  index: number;
  selectedCount?: number;
  mainConsigneeName?: string;
  mainProNumber?: string;
}

export const DragMultipleShipments = ({
  draggableId,
  index,
  mainConsigneeName = "-",
  mainProNumber = "-",
  selectedCount = 1,
}: DragMultipleShipmentsProps) => (
  <Draggable key={draggableId} draggableId={draggableId} index={index}>
    {(
      draggableProvided: DraggableProvided,
      snapshot: DraggableStateSnapshot
    ) => (
      <Box
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        sx={draggableProvided.draggableProps.style}
        height="25px"
      >
        {!snapshot.isDragging && <DragIcon />}
        {snapshot.isDragging && selectedCount > 1 && (
          <DraggableSelectionCounter>{selectedCount}</DraggableSelectionCounter>
        )}
        {snapshot.isDragging && (
          <Box
            width="400px"
            display="flex"
            alignItems="center"
            bgcolor="white"
            color="black"
            border={5}
            borderColor="lightblue"
            padding={1}
            boxShadow={
              selectedCount > 1
                ? "2px 2px 0px 0px white, 5px 5px 0px 0px lightblue"
                : ""
            }
          >
            <DragIcon />
            <Typography>{mainConsigneeName}</Typography>
            <Typography marginLeft="auto">{mainProNumber}</Typography>
          </Box>
        )}
      </Box>
    )}
  </Draggable>
);
