// CSpell:ignore pronotes uccc
import { useSplitTreatments } from "@splitsoftware/splitio-react";

export type FeatureFlag =
  | "linehaul-loadboard-v1-client"
  | "linehaul-loadboard-v2-client"
  | "operations-client"
  | "linehaul-client"
  | "dock-automation-client"
  | "operations-inbound-client"
  | "operations-outbound-client"
  | "linehaul-employee-drivers-functionality"
  | "inbound-edit-route-trap-guardrails-phase-2-client"
  | "linehaul-rate-per-mile-client"
  | "linehaul-master-schedule-employee-drivers-client"
  | "linehaul-multi-week-client"
  | "inbound-edit-route-trap-guardrails-phase-3-client"
  | "settings-drivers-view-edit-client"
  | "inbound-available-routes-traps-status-v2-client"
  | "dispatch-client"
  | "inbound-merge-trap-dialog-v2-client"
  | "inbound-merge-route-dialog-v2-client"
  | "inbound-new-table-design-client"
  | "inbound-print-city-card-load-manifest-client"
  | "inbound-print-linehaul-unload-manifest-client"
  | "inbound-print-trap-unload-manifest-report-client"
  | "linehaul-trip-list-client"
  | "inbound-print-trap-load-manifest-client"
  | "dispatch-routes-filter-client"
  | "linehaul-loadboard-pronotes-client"
  | "inbound-quick-filter-client"
  | "dispatch-stops-panel-client"
  | "dispatch-summary-panel-client"
  | "inbound-planning-print-dialog-client"
  | "linehaul-loadboard-unassign-shipment-client"
  | "linehaul-loadboard-reassign-shipment-client"
  | "outbound-trailershub"
  | "dispatch-driver-latest-locations-client"
  | "inbound-routes-and-section-tabs"
  | "linehaul-loadboard-trip-status-change-client"
  | "linehaul-loadboard-schedule-table-view"
  | "linehaul-loadboard-uccc-planning-client"
  | "inbound-unplanned-freight-incomplete-trailers-client"
  | "dispatch-route-redesign-client"
  | "inbound-planning-table-v2-fetch-strategy-client"
  | "linehaul-reship-client"
  | "linehaul-merge-client"
  | "inbound-traps-table-door-column-visibility-client"
  | "inbound-assign-driver-on-merge-client"
  | "inbound-new-planning-table-design-client";

export const useIsFeatureFlagEnabled = (feature: FeatureFlag) => {
  const { treatments, isReady } = useSplitTreatments({ names: [feature] });
  return isReady && treatments[feature].treatment === "on";
};
