// cSpell:ignore TPLN

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { http } from "../../../utils/httpCommon";
import { apiUrls } from "../../../utils/apiUrls";
import { Trap, trapsSchema } from "../../../types/planning/trap.type";
import { Assignment } from "../useDeliveryRoutes.types";
import { toast } from "../../../utils/snackbarHelper";
import { inboundTrapsSchema } from "../../../types/planning/plannedTrap.type";
import { toastMessage } from "../../../constants/strings";
import { useSelectedServiceCenter } from "../../useSelectedServiceCenter";
import { useInvalidateQueryRoutes } from "../useDeliveryRoutes";
import { useInvalidateQueryAppointmentBucketData } from "./useGetAppointmentBucket";
import { useInvalidateAllQueryShipmentDetails } from "./useShipmentDetails";

type UnplannedTrapSummaryKeyProps = {
  sicId: number;
  planDate?: number;
  searchTerm?: string;
};

const keys = {
  getTraps: ({ sicId, planDate, searchTerm }: UnplannedTrapSummaryKeyProps) => [
    "traps",
    sicId,
    planDate,
    searchTerm,
  ],
  trapHeader: (sicId: number) => ["traps", "trap-header", sicId],
  inboundTraps: (sicId: number, date: number) => [
    "traps",
    "inbound",
    sicId,
    date,
  ],
  unplannedTrapsSummary: ({
    sicId,
    planDate,
    searchTerm,
  }: UnplannedTrapSummaryKeyProps) => [
    "traps",
    "unplanned",
    "summary",
    sicId,
    planDate,
    searchTerm,
  ],
};

type UseTrapsOptions = {
  sicId: number;
  searchTerm?: string;
  showEmptyTraps?: boolean;
  enabled?: boolean;
  planDate: number;
};

export function useTraps({
  sicId,
  searchTerm,
  showEmptyTraps,
  enabled = true,
  planDate,
}: UseTrapsOptions) {
  return useQuery({
    queryKey: keys.getTraps({ sicId, planDate, searchTerm }),
    queryFn: async () => {
      const { data, status } = await http.get(apiUrls.getTraps, {
        params: { sicId, searchTerm, planDate },
      });

      return status === 204 ? [] : trapsSchema.parse(data);
    },
    enabled,
    select: (data) =>
      showEmptyTraps ? data : data.filter((trap) => trap.shipmentsCount > 0),
  });
}

type UseInboundTrapsOptions = {
  sicId: number;
  date: number;
};

export const useGetInboundTraps = ({ sicId, date }: UseInboundTrapsOptions) =>
  useQuery({
    queryKey: keys.inboundTraps(sicId, date),
    queryFn: async () => {
      const { data, status } = await http.get(
        apiUrls.getInboundTraps(sicId, date)
      );
      return status === 204 ? [] : inboundTrapsSchema.parse(data);
    },
  });

export const useGetUnplannedTrapsSummary = (
  sicId: number,
  planDate?: number,
  searchTerm?: string,
  showEmptyTraps = true,
  isNewTableEnabled = false
) =>
  useQuery({
    refetchOnMount: isNewTableEnabled ? false : true,
    queryKey: keys.unplannedTrapsSummary({
      sicId,
      planDate,
      searchTerm,
    }),
    queryFn: async () => {
      const { data, status } = await http.get(apiUrls.getTraps, {
        params: {
          sicId,
          searchTerm,
          notInStatus: ["CLDK", "TPLN"].join(","),
          planDate,
        },
      });
      return status === 204 ? [] : trapsSchema.parse(data);
    },
    select: (data) =>
      showEmptyTraps ? data : data.filter((trap) => trap.shipmentsCount > 0),
  });

// Invalidate all traps queries
export function useInvalidateTraps() {
  const queryClient = useQueryClient();
  return () =>
    queryClient.invalidateQueries({
      queryKey: ["traps"],
    });
}

export type CreateTrapRequest = {
  name: string;
  tractorEquipmentId: number | null;
  trailerEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
  doorId: number | null;
  sicId: number;
  unixDate: number;
  assignments: Assignment[];
};

type UseCreateTrapArgs = {
  onSuccess: (data: Trap, variables: CreateTrapRequest) => void;
  date: number;
  sicId: number;
  onError?: (error: Error) => void;
};

export const useCreateTrap = ({
  onSuccess,
  date,
  sicId,
  onError,
}: UseCreateTrapArgs) => {
  const { invalidateQueryAppointmentBucketData } =
    useInvalidateQueryAppointmentBucketData();
  const invalidateTraps = useInvalidateTraps();
  const invalidateQueryRoutes = useInvalidateQueryRoutes();
  return useMutation({
    mutationFn: async (body: CreateTrapRequest) => {
      const url = apiUrls.createTrap(body.sicId, body.unixDate);
      const { data } = await http.post(url, body);
      return data;
    },
    onSuccess,
    onError,
    onSettled: () => {
      invalidateQueryAppointmentBucketData(sicId);
      invalidateTraps();
      invalidateQueryRoutes(date, sicId);
    },
  });
};

export const useDeleteTraps = (successHandler: () => void) => {
  type DeleteTrapsApiError = {
    code: "CANNOT_DELETE_TRAP";
    invalidTrapIds: number[];
    loadedTrapIds: number[];
  };

  const [serviceCenter] = useSelectedServiceCenter();
  return useMutation({
    mutationFn: async (traps: Trap[]) => {
      const trapIds = traps.map((trap) => trap.id);
      const url = apiUrls.deleteTraps(trapIds, serviceCenter.id);
      await http.delete(url);
    },
    onSuccess: successHandler,
    onError: (error, traps: Trap[]) => {
      if (!axios.isAxiosError(error)) {
        return toast(toastMessage.generics.error, {
          variant: "error",
        });
      }
      const deleteTrapsError: DeleteTrapsApiError = error.response?.data;

      const loadedTraps = traps.filter((trap) =>
        deleteTrapsError.loadedTrapIds.includes(trap.id)
      );
      if (loadedTraps.length > 0) {
        const multipleTraps = loadedTraps.length > 1;
        const trapNames = `"${loadedTraps
          .map((trap) => trap.name)
          .join('", "')}"`;

        const text = multipleTraps
          ? `Traps ${trapNames} are loaded, thus cannot be deleted.`
          : `Trap ${trapNames} is loaded, thus cannot be deleted.`;

        toast(text, {
          variant: "warning",
        });
      }

      const invalidTraps = traps.filter((trap) =>
        deleteTrapsError.invalidTrapIds.includes(trap.id)
      );
      if (invalidTraps.length > 0) {
        const multipleTraps = invalidTraps.length > 1;
        const trapNames = `"${invalidTraps
          .map((trap) => trap.name)
          .join('", "')}"`;

        const text = multipleTraps
          ? `Traps ${trapNames} are invalid, thus cannot be deleted.`
          : `Trap ${trapNames} is invalid, thus cannot be deleted.`;

        toast(text, {
          variant: "warning",
        });
      }
    },
  });
};

export const useDeleteTrapById = (
  trapId: number,
  sicId: number,
  onSuccess?: () => void
) => {
  const invalidateTraps = useInvalidateTraps();
  return useMutation({
    mutationFn: () => http.delete(apiUrls.deleteTraps([trapId], sicId)),
    onSuccess: () => {
      invalidateTraps();
      onSuccess?.();
    },
    onError: () =>
      toast(toastMessage.generics.error, {
        variant: "error",
      }),
  });
};

export type MergeTrapRequest = {
  trapIdsToMerge: (number | null)[];
  mergeIntoTrapId: number | null;
  name: string;
  trailerEquipmentId: number | null;
  straightTruckEquipmentId: number | null;
  doorId: number | null;
  date: number;
  sicId: number;
  customName: string | null;
};

export const useMergeTrap = (
  successHandler: (data: Trap, variables: MergeTrapRequest) => void
) => {
  const invalidateTraps = useInvalidateTraps();
  const { invalidateAllQueryShipmentDetails } =
    useInvalidateAllQueryShipmentDetails();

  return useMutation({
    mutationFn: async (body: MergeTrapRequest) => {
      const url = apiUrls.postMergeTrap;
      const { data } = await http.post(url, body);
      return data;
    },
    onSuccess: successHandler,
    onSettled: () => {
      invalidateTraps();
      invalidateAllQueryShipmentDetails();
    },
  });
};
