import dayjs from "dayjs";
import {
  RouteDetail,
  Route,
  SuggestedRoute,
} from "../../hooks/react-query/dispatch/useRoutes";
import { getBusinessDayStart } from "../mockUtils";
import { tagNameToShipmentTagMap } from "../../types/shipmentTag.type";
import { mockDispatchStops } from "./stops.mock";

export const mockDispatchRouteDetail = {
  default: [
    {
      id: 1,
      name: "PDZ-1",
      driver: { id: 123, name: "John Doe", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: getBusinessDayStart().add(3, "hours"),
      },
      nextStop: {
        id: 101,
        name: "Stop 1",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      stops: mockDispatchStops.default.filter(
        (stop) => "routeId" in stop && stop.routeId === 1
      ),
      equipment: {
        trailer: { id: "TRAILER-1", length: 53 },
        tractor: { id: "TRACTOR-1" },
        straightTruck: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "DISP",
      startTime: getBusinessDayStart(),
      stopsCompleted: 1,
    },
    {
      id: 5,
      name: "FUL-1",
      driver: { id: 105, name: "Jane Smith", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 105,
        name: "Stop 2",
        sequence: 1,
        closeTime: dayjs().add(3, "hours"),
        arrivedAt: undefined,
      },
      stops: mockDispatchStops.default.filter(
        (stop) => "routeId" in stop && stop.routeId === 5
      ),
      equipment: {
        trailer: { id: "TRAILER-2", length: 48 },
        tractor: { id: "TRACTOR-2" },
        straightTruck: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(4, "hours"),
      stem: 7,
      totalMiles: 20,
      status: "DISP",
      startTime: getBusinessDayStart(),
      stopsCompleted: 1,
    },
    {
      id: 0,
      name: "PDZ-1",
      driver: { id: 423, name: "John Doe", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 102,
        name: "Stop 3",
        sequence: 3,
        closeTime: dayjs().add(2, "hours"),
        arrivedAt: undefined,
      },
      stops: mockDispatchStops.default,
      equipment: {
        trailer: { id: "TRAILER-3", length: 53 },
        tractor: { id: "TRACTOR-3" },
        straightTruck: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(8, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "DISP",
      startTime: getBusinessDayStart(),
      stopsCompleted: 1,
    },
    {
      id: 6,
      name: "LBY-1",
      driver: { id: 456, name: "Mike O'Connor", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(3, "hours"),
      },
      nextStop: {
        id: 106,
        name: "Stop 4",
        sequence: 1,
        closeTime: dayjs().add(3.5, "hours"),
        arrivedAt: undefined,
      },
      stops: mockDispatchStops.default.filter(
        (stop) => "routeId" in stop && stop.routeId === 6
      ),
      equipment: {
        trailer: { id: "TRAILER-4", length: 48 },
        tractor: { id: "TRACTOR-4" },
        straightTruck: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(7, "hours"),
      stem: 12,
      totalMiles: 35,
      status: "DISP",
      startTime: getBusinessDayStart(),
      stopsCompleted: 1,
    },
    {
      id: 7,
      name: "ONT-2",
      driver: { id: 789, name: "Emily Clarke", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(1.5, "hours"),
      },
      nextStop: {
        id: 107,
        name: "Stop 5",
        sequence: 1,
        closeTime: dayjs().add(4, "hours"),
        arrivedAt: undefined,
      },
      stops: mockDispatchStops.default.filter(
        (stop) => "routeId" in stop && stop.routeId === 7
      ),
      equipment: {
        trailer: { id: "TRAILER-5", length: 53 },
        tractor: { id: "TRACTOR-5" },
        straightTruck: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 13,
      totalMiles: 50,
      status: "DISP",
      startTime: getBusinessDayStart(),

      stopsCompleted: 1,
    },
  ],
} as const satisfies Record<string, RouteDetail[]>;

export const mockDispatchRoutes = {
  empty: [],
  default: [
    {
      id: 1,
      name: "PDZ-1",
      driver: { id: 123, name: "John Doe", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 101,
        name: "Stop 1",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "DISP",
      startTime: getBusinessDayStart(),
      live: {
        deliveries: 2,
        pickups: 5,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["PFCL", "APTR", "CC", "OSAD"]),
    },
    {
      id: 2,
      name: "FUL-1",
      driver: { id: 123, name: "Jane Smith", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 231,
        name: "Stop 2",
        sequence: 2,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "CLDV",
      startTime: getBusinessDayStart().add(-1, "hours"),
      live: {
        deliveries: 2,
        pickups: 5,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["PFCL", "APTR", "CC", "OSAD"]),
    },
    {
      id: 3,
      name: "ANA-1",
      driver: { id: 183, name: "Alice Johnson", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 2345,
        name: "Stop 9",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "CLDK",
      startTime: getBusinessDayStart(),
      live: {
        deliveries: 2,
        pickups: 5,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["PFCL", "APTR", "CC", "OSAD"]),
    },
    {
      id: 4,
      name: "FON-1",
      driver: { id: 123, name: "Bob Williams", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 101,
        name: "Stop 1",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "ARIV",
      startTime: getBusinessDayStart(),
      live: {
        deliveries: 0,
        pickups: 0,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["PFCL", "APTR", "CC", "OSAD"]),
    },
    {
      id: 5,
      name: "FUL-1",
      driver: { id: 123, name: "Eva Davis", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 435,
        name: "Stop G",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "DISP",
      startTime: getBusinessDayStart(),
      live: {
        deliveries: 2,
        pickups: 5,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["PFCL", "APTR", "CC", "OSAD"]),
    },
    {
      id: 6,
      name: "LBY-1",
      driver: { id: 123, name: "Mike O'Connor", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 325,
        name: "Dest F",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "DISP",
      startTime: getBusinessDayStart(),
      live: {
        deliveries: 2,
        pickups: 5,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["PFCL", "APTR", "CC", "OSAD"]),
    },
    {
      id: 7,
      name: "ONT-2",
      driver: { id: 123, name: "Emily Clarke", profilePicture: undefined },
      employeeShift: {
        clockedAt: getBusinessDayStart(),
        lunchAt: dayjs().add(2, "hours"),
      },
      nextStop: {
        id: 987,
        name: "Dest Y",
        sequence: 1,
        closeTime: dayjs().add(1, "hour"),
        arrivedAt: undefined,
      },
      estimatedRouteEndTime: getBusinessDayStart().add(9, "hours"),
      stem: 15,
      totalMiles: 40,
      status: "DISP",
      startTime: getBusinessDayStart(),
      live: {
        deliveries: 2,
        pickups: 5,
        pallets: 3,
        pieces: 50,
        weight: 200,
      },
      projected: {
        deliveries: 1,
        pickups: 3,
        pallets: 4,
        pieces: 25,
        weight: 100,
      },
      totalStops: 3,
      shipmentTags: tagNameToShipmentTagMap(["APTM", "CC", "UC"]),
    },
  ],
} as const satisfies Record<string, Route[]>;

export const mockDispatchSuggestedRoutes = {
  empty: [],
  default: [
    {
      routeId: 1,
      milesAway: 3,
    },
    {
      routeId: 5,
      milesAway: 4,
    },
  ],
} as const satisfies Record<string, SuggestedRoute[]>;
