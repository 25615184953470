import {
  Appointment,
  Route,
  Section,
  Shipment,
} from "../../../services/prePlanningService.types";
import { Trap as PlannedTrap } from "../../../types/planning/plannedTrap.type";
import { ShipmentWithSequence } from "../../../types/planning/shipmentSeq.type";
import { Trap } from "../../../types/planning/trap.type";
import { tags } from "../../tags";

export const mockSectionsData = {
  empty: [],
  default: [
    {
      id: 1,
      title: "WILL CALL",
      name: null,
      shipmentsCount: 2,
      shipmentsPallets: 5,
      shipmentsPieces: 5,
      shipmentsWeight: 400,
      shipmentIds: [1, 2],
      shipmentsWeightUnit: "lbs",
      shipmentsTags: tags,
      bills: 2,
      pallets: 5,
      pieces: 5,
      weight: 400,
      tags,
    },
    {
      id: 7,
      title: "OS&D",
      name: null,
      shipmentsCount: 1,
      shipmentsPallets: 4,
      shipmentsPieces: 4,
      shipmentsWeight: 800,
      shipmentIds: [3],
      shipmentsWeightUnit: "lbs",
      shipmentsTags: tags,
      bills: 1,
      pallets: 4,
      pieces: 4,
      weight: 800,
      tags,
    },
  ],
} as const satisfies Record<string, Section[]>;

export const mockShipment: ShipmentWithSequence = {
  id: 1,
  shipmentId: 1,
  tripNumber: "3333",
  isPlanned: false,
  proNumber: "11231",
  serviceDue: new Date("2022-05-04T17:00:00"),
  serviceDueDate: new Date("2022-05-04T17:00:00"),
  appointmentDate: new Date("2022-05-04T17:00:00"),
  location: null,
  weight: 1000,
  pallets: 2,
  pieces: 3,
  weightUnit: "lbs",
  tags: [],
  destinationSicCode: "MIA",
  shipper: {
    id: 1,
    name: "Paul",
  },
  consignee: {
    id: 2,
    name: "Not Paul",
    address: "Not Paul's House",
    city: "Rome",
    state: "Palermo",
    zipCode: "40402",
  },
  sic: "ONT",
  status: "FULL",
  appointmentEndDate: new Date("2022-05-04T17:00:00"),
  stopSeq: 1,
  eta: "2022-05-04T17:00:00",
  etaDate: new Date("2022-05-04T17:00:00"),
  isManualAssign: false,
  highlightedRow: false,
  presetRouteId: 1,
  presetRouteName: "Route 1",
  futurePlanDates: [],
};

export const mockShipmentData = {
  default: [
    {
      id: 1,
      proNumber: "987654321",
      tripNumber: 9843833,
      shipper: { id: 1, name: "Shipper 1" },
      consignee: {
        id: 1,
        name: "Heavy Co. 1",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 2,
      pieces: 3,
      weight: 400,
      weightUnit: "lbs",
      tags,
      appointmentDate: new Date(2023, 2, 1),
      appointmentEndDate: new Date(2023, 2, 2),
      appointmentTime: 1000,
      serviceDue: new Date(2023, 2, 6),
      location: "On Dock",
      sic: "ETA 2 hours, ONT",
      status: "Trap",
      assignment: "Unassigned",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 1,
      presetRouteName: "Route 1",
      destinationSicCode: "ONT",
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 6),
      futurePlanDates: [],
    },
    {
      id: 2,
      proNumber: "987654322",
      tripNumber: 4948849,
      shipper: { id: 2, name: "Shipper 2" },
      consignee: {
        id: 1,
        name: "Heavy Co. 2",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 3,
      pieces: 2,
      weight: 450,
      weightUnit: "lbs",
      tags: [{ name: "APPT", colorHex: "#20e0aa", description: "Appointment" }],
      appointmentDate: new Date(2023, 2, 2),
      appointmentEndDate: new Date(2023, 2, 3),
      appointmentTime: 1100,
      serviceDue: new Date(2023, 2, 5),
      location: "On Dock",
      sic: null,
      status: "Enrt",
      assignment: "Unassigned",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 2,
      presetRouteName: "Route 2",
      destinationSicCode: null,
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 5),
      futurePlanDates: [20240417],
    },
    {
      id: 3,
      tripNumber: 8772822,
      proNumber: "987654323",
      shipper: { id: 1, name: "Shipper 1" },
      consignee: {
        id: 1,
        name: "Heavy Co. 3",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 4,
      pieces: 4,
      weight: 500,
      weightUnit: "lbs",
      tags,
      appointmentDate: new Date(2023, 2, 3),
      appointmentEndDate: new Date(2023, 2, 5),
      appointmentTime: 1200,
      serviceDue: new Date(2023, 2, 4),
      assignment: "ANA-SHAGG-3",
      location: "On Dock",
      sic: "ONT",
      status: "Enrt",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 3,
      presetRouteName: "Route 3",
      destinationSicCode: "ONT",
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 4),
      futurePlanDates: [20240417],
    },
    {
      id: 4,
      proNumber: "987654324",
      tripNumber: 12473812,
      shipper: { id: 2, name: "Shipper 2" },
      consignee: {
        id: 1,
        name: "Heavy Co.",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 1,
      pieces: 1,
      weight: 400,
      weightUnit: "lbs",
      tags: [{ name: "APPT", colorHex: "#20e0aa", description: "Appointment" }],
      appointmentDate: new Date(2023, 2, 4),
      appointmentEndDate: new Date(2023, 2, 5),
      appointmentTime: 1300,
      serviceDue: new Date(2023, 2, 3),
      assignment: "ANA-SHAGG-4",
      location: "135-235123",
      sic: "ONT",
      status: "Enrt",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 4,
      presetRouteName: "Route 4",
      destinationSicCode: "ONT",
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 3),
      futurePlanDates: [],
    },
    {
      id: 5,
      proNumber: "987654325",
      tripNumber: 7363783,
      shipper: { id: 1, name: "Shipper 1" },
      consignee: {
        id: 1,
        name: "Heavy Co.",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 2,
      pieces: 2,
      weight: 420,
      weightUnit: "lbs",
      tags,
      appointmentDate: new Date(2023, 2, 5),
      appointmentEndDate: new Date(2023, 2, 6),
      appointmentTime: 1400,
      serviceDue: new Date(2023, 2, 2),
      location: "135-164734",
      sic: "ONT",
      status: "Trap",
      assignment: "Unassigned",
      etaDate: null,
      isPlanned: false,
      presetRouteId: 5,
      presetRouteName: "Route 5",
      destinationSicCode: "ONT",
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 2),
      futurePlanDates: [],
    },
    {
      id: 6,
      proNumber: "987654326",
      tripNumber: 87475732,
      shipper: { id: 2, name: "Shipper 2" },
      consignee: {
        id: 1,
        name: "Heavy Co.",
        address: "67 Way Ave.",
        city: "Culver City",
        state: "CA",
        zipCode: "92807",
      },
      pallets: 5,
      pieces: 3,
      weight: 400,
      weightUnit: "lbs",
      tags: [{ name: "APPT", colorHex: "#20e0aa", description: "Appointment" }],
      appointmentDate: new Date(2023, 2, 6),
      appointmentEndDate: new Date(2023, 2, 7),
      appointmentTime: 1500,
      serviceDue: new Date(2023, 2, 1),
      location: "On Dock",
      sic: "ONT",
      status: "Trap",
      assignment: "Unassigned",
      etaDate: null,
      isPlanned: true,
      presetRouteId: 6,
      presetRouteName: "Route 6",
      destinationSicCode: "ONT",
      isManualAssign: false,
      serviceDueDate: new Date(2023, 2, 1),
      futurePlanDates: [],
    },
  ],
} as const satisfies Record<string, Shipment[]>;

export const mockTrapSummaryData: Record<"empty" | "default", Trap[]> = {
  empty: [],
  default: [
    {
      id: 1,
      name: "MON-0700",
      title: "TRAP-000001",
      doorId: 24,
      shipmentsCount: 2,
      shipmentsPallets: 4,
      shipmentsPieces: 4,
      shipmentsWeight: 800,
      shipmentsWeightUnit: "lbs",
      shipmentIds: [1, 2],
      notes: null,
      shipmentsTags: [],
      trailerNumber: null,
      appointmentDate: new Date(2023, 2, 1),
      serviceDueDate: new Date(2023, 2, 1),
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: true,
      bills: 2,
      pallets: 4,
      pieces: 4,
      status: "TRAP",
      tags: [],
      weight: 800,
      allFuturePlanDates: [20240417, 20240418, 20240419],
      trailer: null,
      straightTruck: null,
    },
    {
      id: 2,
      name: "TUE-0630",
      title: "TRAP-000002",
      doorId: 25,
      shipmentsCount: 1,
      shipmentsPallets: 2,
      shipmentsPieces: 2,
      shipmentsWeight: 400,
      shipmentsWeightUnit: "lbs",
      shipmentIds: [6],
      notes: null,
      shipmentsTags: [],
      trailerNumber: null,
      appointmentDate: new Date(2023, 2, 1),
      serviceDueDate: new Date(2023, 2, 1),
      allShipmentsPlanned: true,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      bills: 1,
      pallets: 2,
      pieces: 2,
      status: "TRAP",
      tags: [],
      weight: 400,
      allFuturePlanDates: [20240417, 20240418, 20240419],
      trailer: {
        id: 2895,
        name: "ONTGHOST08",
      },
      straightTruck: null,
    },
    {
      id: 3,
      shipmentIds: [],
      title: "TRAP-000003",
      notes: null,
      name: "WED-0600",
      doorId: 18,
      trailerNumber: "LAX038",
      shipmentsPieces: 0,
      shipmentsPallets: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      shipmentsCount: 0,
      appointmentDate: new Date(2023, 2, 1),
      serviceDueDate: new Date(2023, 2, 1),
      shipmentsTags: [],
      allShipmentsPlanned: false,
      allShipmentsPlannedForFuture: false,
      someShipmentsPlanned: false,
      allFuturePlanDates: [],
      trailer: {
        id: 24763,
        name: "LAX038",
      },
      straightTruck: null,
      tags: [],
      status: "TRAP",
      bills: 0,
      pallets: 0,
      pieces: 0,
      weight: 0,
    },
  ],
};

export const mockPlannedTraps = {
  empty: [],
  default: [
    {
      id: 84152,
      name: "R-LAX038",
      title: "TRAP-084152",
      type: "Physical",
      status: "TRAP",
    },
    {
      id: 84151,
      name: "R-LAX044",
      title: "TRAP-084151",
      type: "Physical",
      status: "TRAP",
    },
    {
      id: 83669,
      name: "R-LAX044",
      title: "TRAP-083669",
      type: "Physical",
      status: "TRAP",
    },
  ],
} as const satisfies Record<string, PlannedTrap[]>;

export const mockAppointmentBucketTableData = {
  empty: [],
  default: [
    {
      title: "A-01",
      shipmentIds: [1],
      shipmentsCount: 1,
      shipmentsPallets: 12,
      shipmentsPieces: 15,
      shipmentsWeight: 200,
      shipmentsWeightUnit: "lbs",
      bills: 1,
      pallets: 12,
      pieces: 15,
      weight: 200,
      allShipmentsPlanned: false,
    },
    {
      title: "A-02",
      shipmentIds: [2, 3, 4, 5],
      shipmentsCount: 4,
      shipmentsPallets: 11,
      shipmentsPieces: 12,
      shipmentsWeight: 2570,
      shipmentsWeightUnit: "lbs",
      bills: 4,
      pallets: 11,
      pieces: 12,
      weight: 2570,
      allShipmentsPlanned: false,
    },
    {
      title: "A-03",
      shipmentIds: [3, 4, 5, 6],
      shipmentsCount: 4,
      shipmentsPallets: 1110,
      shipmentsPieces: 12,
      shipmentsWeight: 2570,
      shipmentsWeightUnit: "lbs",
      bills: 4,
      pallets: 1110,
      pieces: 12,
      weight: 504,
      allShipmentsPlanned: false,
    },
    {
      title: "A-04",
      shipmentIds: [2, 3, 4, 5, 6],
      shipmentsCount: 5,
      shipmentsPallets: 5,
      shipmentsPieces: 17,
      shipmentsWeight: 2000,
      shipmentsWeightUnit: "lbs",
      bills: 5,
      pallets: 5,
      pieces: 17,
      weight: 2000,
      allShipmentsPlanned: false,
    },
  ],
} as const satisfies Record<string, Appointment[]>;

export const mockRoutesSummaryData = {
  empty: [],
  default: [
    {
      shipmentIds: [1, 2],
      shipmentsCount: 2,
      shipmentsPallets: 5,
      shipmentsPieces: 5,
      shipmentsWeight: 400,
      shipmentsWeightUnit: "lbs",
      id: 1,
      title: "First Mocked Route",
      name: "First Mocked Route",
      type: "Manual",
      isAvailableForMassage: true,
      door: 11,
      zone: "South LA County Mid",
      location: "11",
      startTime: new Date(),
      endTime: null,
      endPoint: null,
      driver: null,
      trailer: {
        id: 1061,
        name: "DYLT161625",
        isActive: true,
      },
      tractor: null,
      straightTruck: null,
      status: "CPLN",
      shipmentsTags: [
        {
          id: 7,
          name: "OSAD",
          description: "Overage, Shortage or Damaged",
          colorHex: "#FF8F7A",
        },
      ],
      allShipmentsPlanned: true,
      bills: 1,
      pallets: 1,
      pieces: 0,
      weight: 600,
      tags: [
        {
          id: 7,
          name: "OSAD",
          description: "Overage, Shortage or Damaged",
          colorHex: "#FF8F7A",
        },
      ],
      doorId: 11,
      allFuturePlanDates: [20240417, 20240418, 20240419],
    },
    {
      shipmentIds: [3],
      shipmentsCount: 1,
      shipmentsPallets: 4,
      shipmentsPieces: 4,
      shipmentsWeight: 400,
      shipmentsWeightUnit: "lbs",
      id: 2,
      title: "Second Mocked Route",
      name: "Second Mocked Route",
      type: "Manual",
      isAvailableForMassage: true,
      door: 11,
      zone: "South LA County Mid",
      location: "11",
      startTime: new Date(),
      endTime: null,
      endPoint: null,
      driver: null,
      trailer: {
        id: 1061,
        name: "DYLT161625",
        isActive: true,
      },
      tractor: null,
      straightTruck: null,
      status: "CPLN",
      shipmentsTags: [
        {
          id: 7,
          name: "OSAD",
          description: "Overage, Shortage or Damaged",
          colorHex: "#FF8F7A",
        },
      ],
      allShipmentsPlanned: false,
      bills: 1,
      pallets: 1,
      pieces: 0,
      weight: 600,
      tags: [
        {
          id: 7,
          name: "OSAD",
          description: "Overage, Shortage or Damaged",
          colorHex: "#FF8F7A",
        },
      ],
      doorId: 2,
      allFuturePlanDates: [20240417, 20240418, 20240419],
    },
    {
      shipmentIds: [],
      shipmentsCount: 0,
      shipmentsPallets: 0,
      shipmentsPieces: 0,
      shipmentsWeight: 0,
      shipmentsWeightUnit: "lbs",
      id: 3,
      title: "Third Mocked Route",
      name: "Third Mocked Route",
      type: "Manual",
      isAvailableForMassage: true,
      door: 11,
      zone: "South LA County Mid",
      location: "11",
      startTime: new Date(),
      endTime: null,
      endPoint: null,
      driver: null,
      trailer: {
        id: 1061,
        name: "DYLT161625",
        isActive: true,
      },
      tractor: null,
      straightTruck: null,
      status: "CPLN",
      shipmentsTags: [
        {
          id: 7,
          name: "OSAD",
          description: "Overage, Shortage or Damaged",
          colorHex: "#FF8F7A",
        },
      ],
      allShipmentsPlanned: false,
      bills: 1,
      pallets: 1,
      pieces: 0,
      weight: 600,
      tags: [
        {
          id: 7,
          name: "OSAD",
          description: "Overage, Shortage or Damaged",
          colorHex: "#FF8F7A",
        },
      ],
      doorId: 2,
      allFuturePlanDates: [20240417, 20240418, 20240419],
    },
  ],
} as const satisfies Record<string, Route[]>;
