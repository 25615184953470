import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUrls } from "../../../../utils/apiUrls";
import { http } from "../../../../utils/httpCommon";
import {
  AssignToRequest,
  UnassignRequest,
} from "../../../../services/prePlanningService.types";
import { toast } from "../../../../utils/snackbarHelper";
import { toastMessage } from "../../../../constants/strings";

type UseAssignShipmentsArgs = {
  onSuccess: (successInfo: {
    affectedShipmentsCount: number;
    sourceRouteId?: number;
    routeId?: number;
  }) => Promise<void> | void;
};

export const useAssignShipments = ({ onSuccess }: UseAssignShipmentsArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: AssignToRequest) => {
      const { status } = await http.post(apiUrls.reassignShipments(), request);
      return status;
    },
    onSuccess: (_, variables) => {
      const uniqueShipmentIds = new Set(
        variables.origins.flatMap((s) => s.shipmentIds)
      );

      if (variables.origins.length > 0) {
        const sourceRouteId =
          variables.origins[0].type === "Route"
            ? Number(variables.origins[0].id)
            : undefined;
        const routeId =
          variables.target.type === "Route" &&
          variables.target.isDroppedOnHeader
            ? Number(variables.target.id)
            : undefined;

        const affectedShipmentsCount = uniqueShipmentIds.size;

        onSuccess({
          affectedShipmentsCount,
          sourceRouteId,
          routeId,
        });
      }
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      // Invalidating all queries because the list was long and complex: https://github.com/daylighttransport/operations-iol-webapp/pull/1370/files
      queryClient.invalidateQueries();
    },
  });
};

export const useUnassignShipments = ({ onSuccess }: UseAssignShipmentsArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: UnassignRequest) => {
      const { status } = await http.post(apiUrls.reassignShipments(), request);
      return status;
    },
    onSuccess: (_, variables) => {
      if (variables.origins.length > 0) {
        const routeId =
          variables.origins[0].type === "Route"
            ? Number(variables.origins[0].id)
            : undefined;
        const affectedShipmentsCount = variables.origins.flatMap(
          (origin) => origin.shipmentIds
        ).length;
        onSuccess({
          affectedShipmentsCount,
          routeId,
        });
      }
    },
    onError: () => toast(toastMessage.generics.error, { variant: "error" }),
    onSettled: () => {
      // Invalidating all queries because the list was long and complex: https://github.com/daylighttransport/operations-iol-webapp/pull/1370/files
      queryClient.invalidateQueries();
    },
  });
};
