import {
  Home,
  Settings,
  SwapHoriz,
  Tablet,
  Warehouse,
} from "@mui/icons-material";
import { green, grey, indigo, lightBlue, orange } from "@mui/material/colors";
import { ReactNode } from "react";
import { useMatch } from "react-router-dom";
import { Theme, useTheme } from "@mui/material";
import { useIsFeatureFlagEnabled } from "../../../featureFlags/useIsFeatureFlagEnabled";
import { useCurrentUser } from "../../../hooks/react-query/settings/security/useUsers";
import { useMySegments } from "../../../hooks/react-query/splitIoApi/useMySegments";

export const HOME_URL_KEY = "/";
export const OPERATIONS_URL_KEY = "operations";
export const LINEHAUL_URL_KEY = "linehaul";
export const DOCK_AUTOMATION_URL_KEY = "dock-automation";
export const SETTINGS_URL_KEY = "settings";

export type DepartmentUrlKey =
  | typeof OPERATIONS_URL_KEY
  | typeof LINEHAUL_URL_KEY
  | typeof DOCK_AUTOMATION_URL_KEY
  | typeof SETTINGS_URL_KEY
  | typeof HOME_URL_KEY;

export type DepartmentChildRoute = {
  label: string;
  route: string;
  enabled: boolean;
};

export type Department = {
  urlKey: DepartmentUrlKey;
  label: string;
  icon: ReactNode;
  active: boolean;
  accentColor: string;
  childRoutes: DepartmentChildRoute[];
  enabled: boolean;
  showServiceCenterSelect: boolean;
};

export function useDepartments(): Department[] {
  const theme = useTheme();
  const { data: user } = useCurrentUser();
  const { data: mySegments = [] } = useMySegments(user?.id ?? "");

  const home = useHome(theme);
  const operations = useOperations(theme);
  const linehaul = useLinehaul(theme);
  const dockAutomation = useDockAutomation(theme);
  const settings = useSettings(theme);

  if (!user) return [];

  return [
    // If the user is only assigned to one segment, that means they only use one department,
    // so we hide home and redirect them directly to the single department.
    ...(mySegments.length === 1 ? [] : [home]),
    operations,
    linehaul,
    dockAutomation,
    settings,
  ];
}

function useOperations(theme: Theme): Department {
  const operationsEnabled = useIsFeatureFlagEnabled("operations-client");

  const operationsInboundEnabled = useIsFeatureFlagEnabled(
    "operations-inbound-client"
  );

  const operationsOutboundEnabled = useIsFeatureFlagEnabled(
    "operations-outbound-client"
  );

  const dispatchEnabled = useIsFeatureFlagEnabled("dispatch-client");

  return {
    urlKey: OPERATIONS_URL_KEY,
    label: "Operations",
    icon: (
      <Warehouse
        fontSize="inherit"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
    active: !!useMatch("/" + OPERATIONS_URL_KEY + "/*"),
    accentColor: orange["500"],
    childRoutes: [
      {
        label: "Inbound",
        route: "operations/:serviceCenterCode/inbound",
        enabled: operationsInboundEnabled,
      },
      {
        label: "Outbound",
        route: "operations/:serviceCenterCode/outbound",
        enabled: operationsOutboundEnabled,
      },
      {
        label: "Dispatch",
        route: "operations/:serviceCenterCode/dispatch",
        enabled: dispatchEnabled,
      },
    ],
    enabled: operationsEnabled,
    showServiceCenterSelect: true,
  };
}

function useLinehaul(theme: Theme): Department {
  const linehaulEnabled = useIsFeatureFlagEnabled("linehaul-client");
  const loadboardV1Enabled = useIsFeatureFlagEnabled(
    "linehaul-loadboard-v1-client"
  );
  const loadboardV2Enabled = useIsFeatureFlagEnabled(
    "linehaul-loadboard-v2-client"
  );

  return {
    urlKey: LINEHAUL_URL_KEY,
    label: "Linehaul",
    icon: (
      <SwapHoriz
        fontSize="inherit"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
    active: !!useMatch("/" + LINEHAUL_URL_KEY + "/*"),
    accentColor: indigo["600"],
    childRoutes: [
      {
        label: "Master Schedule",
        route: "linehaul/master-schedule",
        enabled: true,
      },
      {
        label: "Templates",
        route: "linehaul/master-schedule-templates",
        enabled: true,
      },
      {
        label: "Load Board",
        route: "linehaul/load-board",
        enabled: loadboardV1Enabled,
      },
      {
        label: "Load Board V2",
        route: "linehaul/load-board-v2",
        enabled: loadboardV2Enabled,
      },
    ],
    enabled: linehaulEnabled,
    showServiceCenterSelect: false,
  };
}

function useDockAutomation(theme: Theme): Department {
  return {
    urlKey: DOCK_AUTOMATION_URL_KEY,
    label: "Dock Auto",
    icon: (
      <Tablet
        fontSize="inherit"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
    active: !!useMatch("/" + DOCK_AUTOMATION_URL_KEY + "/*"),
    accentColor: green["400"],
    childRoutes: [],
    enabled: false,
    showServiceCenterSelect: false,
  };
}

function useSettings(theme: Theme): Department {
  return {
    urlKey: SETTINGS_URL_KEY,
    label: "Settings",
    icon: (
      <Settings
        fontSize="inherit"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
    active: !!useMatch("/" + SETTINGS_URL_KEY + "/*"),
    accentColor: grey["600"],
    childRoutes: [],
    enabled: true,
    showServiceCenterSelect: true,
  };
}

function useHome(theme: Theme): Department {
  return {
    urlKey: HOME_URL_KEY,
    label: "Home",
    icon: (
      <Home
        fontSize="inherit"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
    active: !!useMatch(HOME_URL_KEY),
    accentColor: lightBlue["700"],
    childRoutes: [],
    enabled: true,
    showServiceCenterSelect: false,
  };
}
