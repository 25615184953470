import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { z } from "zod";
import { AxiosError } from "axios";
import { http } from "../../../../utils/httpCommon";
import { apiUrls } from "../../../../utils/apiUrls";
import { ModuleDetail } from "./useModules";

const permissionKeys = {
  getPermissionModule: (moduleId: number) => [
    "settings",
    "security",
    "modules",
    moduleId,
  ],
  permissions: () => ["settings", "security", "modules", "permissions"],
};

export const permissionSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  isActive: z.boolean(),
  apiMethod: z.string(),
  apiRoute: z.string(),
  moduleId: z.number(),
  modifiedBy: z.string().nullable().optional(),
  modifiedOn: z
    .date()
    .optional()
    .transform((val) => dayjs(val)),
});

export const createPermissionSchema = z.object({
  name: z.string().min(1, "Name must not be empty"),
  description: z.string(),
  apiMethod: z.string().min(3, "Existing methods have at least 3 characters."),
  apiRoute: z.string().min(1, "Route must not be empty"),
});

const createPermissionError = z.object({
  errors: z.object({
    data: z.array(z.string()),
  }),
});

export type Permission = z.infer<typeof permissionSchema>;

export const usePermissions = () =>
  useQuery({
    queryKey: permissionKeys.permissions(),
    queryFn: async () => {
      const { data } = await http.get(apiUrls.getPermissionsV2);
      return permissionSchema.array().parse(data);
    },
  });

type CreatePermission = z.infer<typeof createPermissionSchema>;
type CreatePermissionArgs = {
  onSuccess?: () => void;
  onError?: (error: string) => void;
  onSettled?: () => void;
  moduleId: number;
};

export const useCreatePermission = ({
  onSuccess,
  onError,
  onSettled,
  moduleId,
}: CreatePermissionArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permission: CreatePermission) => {
      await http.post(apiUrls.createPermission(), { ...permission, moduleId });
      return permission;
    },
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (_error) => {
      const error = _error as AxiosError;
      const errorData = createPermissionError.safeParse(error.response?.data);
      if (errorData.success) {
        onError?.(errorData.data.errors.data[0]);
      } else {
        onError?.("Unknown Error");
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: permissionKeys.getPermissionModule(moduleId),
      });
      onSettled?.();
    },
  });
};

type UpdatePermissionArgs = {
  onSuccess?: () => void;
  onError?: () => void;
  onSettled?: () => void;
};

export const useUpdatePermission = ({
  onSuccess,
  onError,
  onSettled,
}: UpdatePermissionArgs) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (permission: Permission) => {
      await http.put(apiUrls.updatePermission(permission.id), permission);
      return permission;
    },
    onMutate: (permission) => {
      queryClient.cancelQueries({
        queryKey: permissionKeys.getPermissionModule(permission.moduleId),
      });
      const previousModule = queryClient.getQueryData<ModuleDetail>(
        permissionKeys.getPermissionModule(permission.moduleId)
      );
      const newPermissions = [...(previousModule?.permissions || [])].map(
        (item) => ({ ...(item.id === permission.id ? permission : item) })
      );
      const newModule = { ...previousModule, permissions: newPermissions };

      queryClient.setQueryData(
        permissionKeys.getPermissionModule(permission.moduleId),
        newModule
      );

      return { previousData: previousModule };
    },
    onSuccess: (permission) => {
      queryClient.invalidateQueries({
        queryKey: permissionKeys.getPermissionModule(permission.moduleId),
      });
      onSuccess?.();
    },
    onError: (_error) => {
      onError?.();
    },
    onSettled: () => {
      onSettled?.();
    },
  });
};

type DeletePermissionArgs = {
  onSuccess?: () => void;
  onError?: (errorMessage: string) => void;
  moduleId: number;
};

export const useDeletePermission = ({
  onSuccess,
  onError,
  moduleId,
}: DeletePermissionArgs) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (permissionId: number) => {
      const url = apiUrls.deletePermission(permissionId);
      const { status } = await http.delete(url);
      return status;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: permissionKeys.getPermissionModule(moduleId),
      });
      onSuccess?.();
    },
    onError: (_error) => {
      onError?.(_error.message);
    },
  });
};
